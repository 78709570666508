import Calculator from "@ebas/klimaberegner";

import { baseUrl } from "../../../api";
import { useStoreState } from "../../../store/rootStore";

function EnergiberegnerPage() {
  const { report } = useStoreState((state) => state.Report);

  if (!report) {
    return <p>Report not found</p>;
  }

  const energyMarkSerial =
    !!report.EnergymarkSerial && report.EnergymarkSerial !== "0"
      ? report.EnergymarkSerial
      : "0";

  return (
    <Calculator
      baseUrl={`${baseUrl()}/api/energySystemsProxy/`.replaceAll(
        "//api",
        "/api",
      )}
      lookup={energyMarkSerial ? { serialIdentifier: energyMarkSerial } : "0"}
    />
  );
}

export default EnergiberegnerPage;
