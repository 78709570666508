import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import React from "react";
import styled from "styled-components";

import Anchor from "../../../../styles/Anchor";

interface IntroGuideProps {
  title: string;
  content: React.ReactNode;
}

export const IntroGuide = ({ title, content }: IntroGuideProps) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  return (
    <>
      <IntroGuideContainer onClick={() => setModalOpen(true)}>
        <InfoOutlined />
        {title}
      </IntroGuideContainer>

      <React.Fragment>
        <Dialog
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          maxWidth={"md"}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{ marginTop: 20 }}>
            {title}
          </DialogTitle>
          <DialogContent style={{ padding: "20px" }}>{content}</DialogContent>
          <DialogActions style={{ marginBottom: 20 }}>
            <Button onClick={() => setModalOpen(false)}>Luk</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </>
  );
};

const IntroGuideContainer = styled(Anchor)`
  display: flex;
  align-items: center;
  gap: 4px;
`;
