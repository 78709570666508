import { useState } from "react";
import styled from "styled-components";

import Room from "./Room";
import Text from "../../../styles/Text";
import UsageImage from "../../../styles/UsageImage";
import { Floor as FloorModel } from "../../../types/ElReport";

function Floor({ floor }: { floor: FloorModel }) {
  const [expandedRooms, setExpandedRooms] = useState<string[]>([]);

  const toggleExpand = (roomName: string) => {
    setExpandedRooms((prevState) =>
      prevState.includes(roomName)
        ? prevState.filter((button) => button !== roomName)
        : [...prevState, roomName],
    );
  };

  return (
    <RoomInfoContainer>
      <RoomNameContent>
        <UsageImage usage="Beboelse" />
        <Text bold size={18}>
          {floor.name}
        </Text>
      </RoomNameContent>
      {floor.rooms.map((room) => {
        const isExpanded = expandedRooms.includes(room.name);
        return (
          <Room
            key={room.id}
            room={room}
            isExpanded={isExpanded}
            onClick={() => toggleExpand(room.name)}
          />
        );
      })}
    </RoomInfoContainer>
  );
}

const RoomInfoContainer = styled.div`
  padding: 32px 0;
  background-color: ${(p) => p.theme.elReportContentBg};
`;

const RoomNameContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-bottom: 8px;
  gap: 12px;
`;

export default Floor;
