import { CircularProgress } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

import initGA from "../helpers/ga";
import { useStoreState } from "../store/rootStore";
import { generic } from "../styles";

const { Container, Header } = generic;

export const PageLayout = () => {
  const [scroll, setScroll] = useState(false);
  const ref = useRef<{ scrollTop: number }>(null);

  const { report, loaded, files } = useStoreState((state) => state.Report);

  const onScroll = () => {
    if (ref.current && ref.current.scrollTop >= 108) {
      setScroll(true);
    } else if (scroll) {
      setScroll(false);
    }
  };

  useEffect(() => {
    if (loaded) {
      initGA(report);
    }
  }, [report, loaded]);

  if (!loaded) {
    return <CircularProgress />;
  }
  if (!report) {
    return <p>Report not found</p>;
  }

  if (!files) {
    return <p>Files not found!</p>;
  }

  return (
    // @ts-ignore
    <Page ref={ref} onScroll={() => onScroll()}>
      <Container>
        <MiddleCon>
          <Header
            scroll={scroll}
            address={`${report?.Estate?.Address}, ${report?.Estate?.ZipCode} ${report?.Estate?.City}`}
          />
          <Main>
            <Outlet />
          </Main>
        </MiddleCon>
      </Container>
    </Page>
  );
};

const Page = styled.div`
  height: 100vh;
  overflow-y: auto;
  width: 100%;
`;
const MiddleCon = styled.div`
  width: 1024px;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const Main = styled.main`
  padding-top: ${(p) => (p.theme.headerText !== "" ? "115px" : "108px")};
`;
