import { Grid } from "@material-ui/core";
import React from "react";
import { useLocation } from "react-router-dom";
import styled, { useTheme } from "styled-components";

// @ts-ignore
import Headline from "./Headline";
// @ts-ignore
import images from "./LogoImages";
import { Navigation } from "./Navigation";
import Text from "./Text";
import { useStoreState } from "../store/rootStore";

/**
 * @param address
 * @param scroll
 * @returns {JSX.Element}
 * @constructor
 */
function Header({ address, scroll }: { address: string; scroll: boolean }) {
  const theme = useTheme();
  const { logoHeader, headerTagline, showElRapport } = useStoreState(
    (state) => state.Features,
  );
  const { elReport } = useStoreState((state) => state.Report);
  const { pathname } = useLocation();
  console.log();
  return (
    <Container scroll={scroll}>
      {showElRapport.isEnabled && elReport && <Navigation />}
      <GridContainer container spacing={0}>
        <ContentContainer scroll={scroll}>
          {logoHeader.isEnabled && (
            <Grid item xs={12} md={1}>
              <picture>
                {/* @ts-ignore */}
                <Img scroll={scroll} src={images[logoHeader.value].src} />
              </picture>
            </Grid>
          )}

          {/* @ts-ignore */}
          <Grid item xs={12} md={theme.company === "" ? 7 : 6}>
            {headerTagline.isEnabled && !scroll && (
              <Text size={16} top={4} bottom={8}>
                {headerTagline.value}
              </Text>
            )}
            <Headline
              top={scroll ? (headerTagline.isEnabled ? 18 : 12) : 0}
              h1={!scroll}
              h2={scroll}
            >
              Visuel{" "}
              {pathname.endsWith("elinstallationsrapport")
                ? "Elrapport"
                : "Tilstandsrapport"}
            </Headline>
          </Grid>
          <Grid item xs={12} md={5}>
            <AddressCon scroll={scroll}>
              <Text size={16} right>
                {address}
              </Text>
            </AddressCon>
          </Grid>
        </ContentContainer>
        <Line scroll={scroll} />
      </GridContainer>
    </Container>
  );
}

const Img = styled.img<{ scroll?: boolean }>`
  height: 70px;
  width: 70px;
  transition: transform 0.3s;
  transform: scale(${(p) => (p.scroll ? 0.5 : 1)});
`;

const ContentContainer = styled.div<{ scroll?: boolean }>`
  display: flex;
  justify-content: space-between;
  transition: transform 0.3s;
  transform: translateY(${(p) => (p.scroll ? "-12px" : "0")});
  width: 100%;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const GridContainer = styled(Grid)`
  margin-top: 24px;
  width: 1024px !important;

  @media (max-width: 1024px) {
    width: 100% !important;
    padding-top: 8px;
  }
`;

const Container = styled.div<{ scroll?: boolean }>`
  //margin-top: 24px;
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  //width: 1024px;
  background-color: #ffffff;
  left: 0;
  z-index: 133700;
`;

const AddressCon = styled.div<{ scroll?: boolean }>`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: ${(p) => (p.scroll ? "center" : p.theme.headerAdressAlign)};
  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

const Line = styled.div<{ scroll?: boolean }>`
  height: 1px;
  width: 100%;
  margin-top: ${(p) => (p.scroll && p.theme.headerText !== "" ? 0 : "16px")};
  background-color: #cccccc;
  @media (max-width: 1024px) {
    margin-top: 0px;
  }
`;

export default Header;
