import { Grid } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

import ElErrorCodeCard from "../../../../components/ElMistakeReport/ElErrorCodeCard";
import BudgetSVG from "../../../../images/symbols/budget.svg";
import ElAgreementSVG from "../../../../images/symbols/elagreement.svg";
import EnergySVG from "../../../../images/symbols/energy.svg";
import EyeWithArrowsSvg from "../../../../images/symbols/eyewitharrows.svg";

const electricReportContents = [
  {
    description:
      "Elinstallationsrapporten beskriver ulovlige forhold og funktionsfejl ved elinstallationen. En installation er ulovlig, hvis den ikke lever op til de standarder og lovkrav, der var gældende, da installationen blev udført. Funktionsfejl i den lovlige del af elinstallationen, fremgår ikke af fejloversigten.",
    image: ElAgreementSVG,
  },
  {
    description:
      "Den autoriserede elinstallatørvirksomhed foretager en visuel gennemgang og udfører stikprøver på baggrund af erfaring og en professionel faglig vurdering. Fagpersonen undersøger installationer, som vækker opmærksomhed, eller hvor der erfaringsmæssigt ofte vil være fejl. Stikprøver udvælges systematisk, og alle typer installationer undersøges. Da det er stikprøver, kan der være ulovlige og farlige installationer i boligen, som ikke er fundet og derfor ikke er beskrevet i rapporten.",
    image: EyeWithArrowsSvg,
  },
  {
    description:
      "Som boligejer er det dit ansvar at få udbedret ulovlige elinstallationer. De fleste fejl i installationerne skal udbedres af en autoriseret elinstallatørvirksomhed. Du kan se, hvilke fejl, du selv må udbedre, på Sikkerhedsstyrelsens hjemmeside www.sik.dk.",
    image: EnergySVG,
  },
  {
    description:
      "De ulovlige fejl i installationen vurderes ud fra, hvor farlig den ulovlige fejl er. Der er ikke nødvendigvis sammenhæng mellem, hvor farlig en fejl er, og hvor dyrt det er at udbedre fejlen. Der kan være fejl, som hurtigt og billigt kan udbedres, men som kan indebære en alvorlig risiko for elektrisk stød eller brand, hvis der ikke gøres noget. Rapporten forholder sig ikke til, hvad det koster at udbedre et ulovligt forhold.",
    image: BudgetSVG,
  },
];
const HowToPrepareElectricalReport = () => {
  return (
    <Container>
      <Grid container style={{ background: "#f2f2f2" }}>
        {Object.values(electricReportContents).map((item, i) => (
          <Grid key={i} item xs={12} md={6}>
            <ElErrorCodeCard
              description={item.description}
              image={item.image}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default HowToPrepareElectricalReport;
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;

  & > div {
    flex: 1 1 calc(25% - 40px);
  }

  @media (max-width: 832px) {
    & > div {
      flex: 1 1 calc(50% - 10px);
    }
  }
`;
