import { Button } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { useState } from "react";
import styled from "styled-components";

import Floor from "./Floor";
import {
  Building as BuildingModel,
  Floor as FloorModel,
} from "../../../types/ElReport";

function Building({ building }: { building: BuildingModel | undefined }) {
  const [hiddenButtons, setHiddenButtons] = useState<string[]>([]);

  if (!building) {
    return null;
  }

  const toggleButton = (label: string) => {
    setHiddenButtons((prevState) => {
      const newState = prevState.includes(label)
        ? prevState.filter((button) => button !== label)
        : [...prevState, label];
      return sortList(newState);
    });
  };

  const sortList = (hiddenButtons: string[]) => {
    const indexMap = new Map();

    building.floors?.forEach((item, index: number) => {
      indexMap.set(item.name, index);
    });

    return hiddenButtons.sort((a, b) => {
      const indexA = indexMap.get(a);
      const indexB = indexMap.get(b);
      return indexA - indexB;
    });
  };

  const getFloor = (floorName: string) => {
    const floor = building.floors.find((floor) => floor.name === floorName);

    if (!floor) {
      throw new Error("Der er ingen information på etagen: " + floorName);
    }

    return floor;
  };

  const getFloorList = (floors: FloorModel[]): FloorModel[] => {
    return floors.filter((floor) =>
      floor.rooms?.some((room) => room.issues?.length > 0),
    );
  };

  return (
    <>
      {building.floors?.some((floor) =>
        floor.rooms?.some((room) => room.issues?.length > 0),
      ) && (
        <>
          <div>
            <ToggleLabel>Vælg rum</ToggleLabel>
            <ToggleContainer>
              {getFloorList(building.floors)?.map((floor) => (
                <FloorButton
                  key={floor.id}
                  isHidden={hiddenButtons.includes(floor.name)}
                  onClick={() => toggleButton(floor.name)}
                  endIcon={
                    !hiddenButtons.includes(floor.name) && <ButtonCheckMark />
                  }
                >
                  {floor.name}
                </FloorButton>
              ))}
            </ToggleContainer>
          </div>
          {building.floors?.map(
            (floor) =>
              !hiddenButtons.includes(floor.name) &&
              floor.rooms?.some((room) => room.issues?.length > 0) && (
                <div key={floor.name}>
                  <Floor floor={getFloor(floor.name)} />
                </div>
              ),
          )}
        </>
      )}
    </>
  );
}

const ToggleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 6px;
`;
const ButtonCheckMark = styled(Check)`
  //margin-left: 8px;
  color: ${(p) => p.theme.elReportButtonTextColor} !important;
  height: 0.8em !important;
`;

const FloorButton = styled(Button)<{ isHidden: boolean }>`
  padding: 8px 16px !important;
  border-radius: 12px !important;
  align-items: initial;
  color: ${(p) => p.theme.elReportButtonTextColor} !important;
  background: ${(p) =>
    p.isHidden
      ? p.theme.elReportInactiveButtonColor
      : p.theme.elReportButtonColor} !important;
  box-shadow: none;

  &:hover {
    background: ${(p) => p.theme.elReportButtonHoverColor} !important;
  }
`;

const ToggleLabel = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
`;

export default Building;
