import {
  Bar,
  BarChart,
  CartesianGrid,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { TooltipProps } from "recharts/types/component/Tooltip";
import styled, { useTheme } from "styled-components";

import { incidentTypeToIcon } from "../../../helpers/elReportIssue";
import UsageImage from "../../../styles/UsageImage";
import { IncidentType } from "../../../types/ElReport";
import { ThemeType } from "../../../types/Theme";

export type IncidentGraphData = {
  incidentAmount: number;
  incidentType: IncidentType;
};

const customizedGroupTick = ({ x, y, payload }: any) => {
  const imageSize = 25;
  const xOffset = imageSize / 2;

  return (
    <g transform={`translate(${x - xOffset},${y})`}>
      <foreignObject width={imageSize} height={imageSize}>
        <UsageImage
          size={imageSize}
          usage={incidentTypeToIcon(payload.value)}
        />
      </foreignObject>
    </g>
  );
};
const getDescription = (label: IncidentType) => {
  switch (label) {
    case IncidentType.FIRE:
      return "Ulovlige elinstallationer, der indebærer risiko for brand";
    case IncidentType.SHOCK:
      return "Ulovlige elinstallationer, der indebærer risiko for elektrisk stød.";
    case IncidentType.ILLEGAL:
      return "Elinstallationer, der ikke lever op til de standarder og lovkrav, der gjaldt, da installationen blev udført";
    case IncidentType.INVESTIGATE:
      return `Forhold ved elinstallationerne, der bør undersøges nærmere for at afklare, om der er en fejl. Det kan
      være en fejl med risiko for brand eller stød, og derfor bør du undersøge forholdet nærmere. Hvis du
      vælger at købe ejendommen, uden at forholdet er undersøgt nærmere, bærer du selv risikoen.`;
    default:
      return "";
  }
};

const getIncidentTitle = (label: IncidentType) => {
  switch (label) {
    case IncidentType.FIRE:
      return "Risiko for brand";
    case IncidentType.SHOCK:
      return "Risiko for stød";
    case IncidentType.ILLEGAL:
      return "Ulovlige elinstallationer";
    case IncidentType.INVESTIGATE:
      return "Undersøges nærmere";
    default:
      return "";
  }
};

const customizedToolTip = ({
  active,
  payload,
  label,
}: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    return (
      <TooltipWrapper>
        <TooltipTitle>{`${payload[0].value} ${getIncidentTitle(
          label,
        )}`}</TooltipTitle>
        <p>{getDescription(label)}</p>
      </TooltipWrapper>
    );
  }
  return <h1>NO</h1>;
};

const IncidentGraph = ({
  incidentData,
}: {
  incidentData: IncidentGraphData[];
}) => {
  const theme = useTheme() as ThemeType;
  // const testIncidentData = [
  //   { incidentAmount: 1, incidentType: IncidentType.FIRE },
  //   { incidentAmount: 0, incidentType: IncidentType.SHOCK },
  //   { incidentAmount: 0, incidentType: IncidentType.ILLEGAL },
  //   { incidentAmount: 55, incidentType: IncidentType.INVESTIGATE },
  // ];
  const maxIncidentAmount = Math.max(
    ...incidentData.map((d) => d.incidentAmount),
  );
  const ticks = Array.from(
    {
      length:
        Math.ceil((maxIncidentAmount + (5 - (maxIncidentAmount % 5))) / 5) + 1,
    },
    (_, i) => i * 5,
  );

  return (
    <GraphWrapper>
      <ResponsiveContainer height={250}>
        <BarChart
          data={incidentData}
          margin={{ right: 40, left: -20, bottom: 10 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="incidentType"
            interval={0}
            tick={customizedGroupTick}
          />
          <YAxis allowDecimals={false} ticks={ticks} min={5} />
          <Tooltip cursor={{ fill: "none" }} content={customizedToolTip} />

          <Bar
            dataKey="incidentAmount"
            fill={theme.elReportGraphColor}
            activeBar={
              <Rectangle
                fill={theme.elReportGraphHoverColor}
                stroke={theme.elReportGraphColor}
              />
            }
          />
        </BarChart>
      </ResponsiveContainer>
    </GraphWrapper>
  );
};

const GraphWrapper = styled.div`
  max-width: 500px;
  min-width: 300px;
`;

const TooltipWrapper = styled.div`
  background-color: white;
  opacity: 0.8;
  padding: 10px;
  max-width: 250px;
  min-width: 200px;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.3);
`;

const TooltipTitle = styled.p`
  font-weight: bold;
`;

export default IncidentGraph;
