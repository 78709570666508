export type ErrorType = {
  image: string;
  title?: string;
  description: string;
};

export enum ErrorTypeSymbol {
  FIRE,
  SHOCK,
  ILLEGAL,
  INVESTIGATE,
}

export type ElMistakeCaseType = {
  header: string;
  subtitle?: string;
  mistakes: ElMistakeType[];
  errorTypes?: ErrorTypeSymbol[];
  errorTypeComments?: string[];
};

type ElMistakeType = {
  images?: string[];
  descriptions: ElMistakeDescriptionType[];
};

type ElMistakeDescriptionType = {
  question: string;
  answer: string;
};
