import { Button } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Navigation = () => {
  return (
    <Container>
      <NavigationLink to={`tilstandsrapport`}>
        {({ isActive }) => (
          <Button
            style={buttonStyle(isActive)}
            variant={isActive ? "contained" : "text"}
          >
            Tilstandsrapport
          </Button>
        )}
      </NavigationLink>

      <NavigationLink to={"elinstallationsrapport"}>
        {({ isActive }) => (
          <Button
            style={buttonStyle(isActive)}
            variant={isActive ? "contained" : "text"}
          >
            Elinstallationsrapport
          </Button>
        )}
      </NavigationLink>
    </Container>
  );
};

export const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: #f3f3f3;
  width: 100%;
  box-shadow: inset 0 -8px 7px -11px rgba(0, 0, 0, 0.37);
`;

const NavigationLink = styled(NavLink)`
  color: white;
  text-decoration: none;
`;

const buttonStyle = (isActive: boolean) => ({
  padding: "6px 16px",
  backgroundColor: isActive ? "#fff" : "transparent",
  borderRadius: isActive ? "5px 5px 0px 0px" : "0px",
  boxShadow: "none",
  borderRight: isActive ? "1px solid rgba(0, 0, 0, 0.27)" : "none",
  borderLeft: isActive ? "1px solid rgba(0, 0, 0, 0.27)" : "none",
});
