import {
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from "@material-ui/icons";
import { animated, config, useSpring } from "@react-spring/web";
import styled from "styled-components";

import Issue from "./Issue";
import {
  getIssueType,
  incidentTypeToIcon,
} from "../../../helpers/elReportIssue";
import { useStoreState } from "../../../store/rootStore";
import SText from "../../../styles/Text";
import UsageImage from "../../../styles/UsageImage";
import {
  IncidentType,
  Issue as IssueModel,
  Room as RoomModel,
} from "../../../types/ElReport";

function Room({
  room,
  isExpanded,
  onClick,
}: {
  room: RoomModel;
  isExpanded: boolean;
  onClick: () => void;
}) {
  const { elReportQuestions } = useStoreState((state) => state.Report);

  const expandAnimation = useSpring({
    opacity: isExpanded ? 1 : 0,
    from: { opacity: 0 },
    config: { ...config.default, duration: 250 },
  });

  const disassembled = room.issues?.filter((_) => _.type === "separated");

  const approved = room.issues?.filter((_) => _.text?.toLowerCase() === "ja");

  const disapproved = room.issues?.filter(
    (_) => _.text?.toLowerCase() !== "ja" && _.type !== "separated",
  );

  const roomIssueIcons = getRoomIssueIcons({ issues: room.issues });
  const roomIssuesCount = room.issues?.reduce((count, issue) => {
    return getIssueType(issue) !== IncidentType.NONE ? count + 1 : count;
  }, 0);

  return (
    <>
      {room.issues && (
        <>
          <InfoHeaderContent onClick={onClick}>
            <HeaderWrapper>
              <IncidentContainer>
                {roomIssueIcons.length === 0 ? (
                  <UsageImage size={35} usage={"Accept"} />
                ) : (
                  roomIssueIcons.map((icon, i) => (
                    <UsageImage key={i} size={35} usage={icon} />
                  ))
                )}

                {room.issues?.length && (
                  <Text>
                    {roomIssuesCount > 0 && (
                      <>
                        <b>{roomIssuesCount}</b> anmærkning
                        {roomIssuesCount > 1 && "er"} i{" "}
                      </>
                    )}
                    <b>{room.issues?.length}</b> undersøgte forhold
                  </Text>
                )}
              </IncidentContainer>
              <RoomExpand>
                <StyledRoomName bold>{room?.name}</StyledRoomName>
                {isExpanded ? <ArrowUp /> : <ArrowDown />}
              </RoomExpand>
            </HeaderWrapper>
          </InfoHeaderContent>
          <ExpandAnimation style={expandAnimation}>
            {isExpanded && (
              <ExpandContent>
                {disassembled?.length > 0 && (
                  <ExpandSection>
                    <Text bold={true} bottom={4}>
                      DETTE HAR VÆRET ADSKILT
                    </Text>
                    <ListStyle>
                      {disassembled.map((issue) => {
                        return (
                          <li>
                            <Text bold={true} bottom={4}>
                              {issue.text}
                            </Text>
                          </li>
                        );
                      })}
                    </ListStyle>
                  </ExpandSection>
                )}
                {approved.length > 0 && (
                  <>
                    <Text bold={true} bottom={4}>
                      DISSE FORHOLD ER UNDERSØGT OG FUNDET OK
                    </Text>
                    <ExpandSection>
                      {approved.map((issue) => (
                        <Issue
                          key={issue.id}
                          issue={issue}
                          questions={elReportQuestions}
                        />
                      ))}
                    </ExpandSection>
                  </>
                )}
                {disapproved.length > 0 && (
                  <>
                    <Text bold={true} bottom={4}>
                      DISSE FORHOLD ER UNDERSØGT OG FUNDET IKKE OK
                    </Text>
                    <ExpandSection>
                      {disapproved.map((issue) => (
                        <Issue
                          key={issue.id}
                          issue={issue}
                          questions={elReportQuestions}
                        />
                      ))}
                    </ExpandSection>
                  </>
                )}
              </ExpandContent>
            )}
          </ExpandAnimation>
        </>
      )}
    </>
  );
}

const getRoomIssueIcons = ({ issues }: { issues: IssueModel[] }) => {
  const uniqueIcons = new Set<string>();

  issues?.forEach((issue) => {
    uniqueIcons.add(incidentTypeToIcon(getIssueType(issue)));
  });

  return Array.from(uniqueIcons).sort((a, b) => a.localeCompare(b));
};

const InfoHeaderContent = styled.div`
  cursor: pointer;
  background: ${(p) =>
    `linear-gradient(to right, ${p.theme.elReportAccordionPrimaryColor}, ${p.theme.elReportAccordionPrimaryColor})`};
  margin-top: 10px;
  border-left: ${(p) => `5px solid ${p.theme.elReportAccordionSecondaryColor}`};
  transition: background 0.5s ease-in-out; /* Smooth transition */

  &:hover {
    background: ${(p) =>
      `linear-gradient(to right, ${p.theme.elReportAccordionSecondaryColor}, ${p.theme.elReportAccordionPrimaryColor})`};
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`;

const Text = styled(SText)`
  color: ${(p) => p.theme.elReportAccordionTextColor};
`;

const ListStyle = styled.ul`
  color: ${(p) => p.theme.elReportAccordionTextColor};
`;

const IncidentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const RoomExpand = styled.div`
  display: inherit;
  align-items: center;
`;

const ArrowDown = styled(KeyboardArrowDownRounded)`
  color: ${(p) => p.theme.elReportAccordionTextColor};
`;

const ArrowUp = styled(KeyboardArrowUpRounded)`
  color: ${(p) => p.theme.elReportAccordionTextColor};
`;

const ExpandAnimation = styled(animated.div)`
  overflow: hidden;
`;

const ExpandContent = styled.div`
  padding: 24px;
  background: ${(p) => p.theme.elReportAccordionPrimaryColor};
  border-left: ${(p) => `5px solid ${p.theme.elReportAccordionSecondaryColor}`};
`;

const ExpandSection = styled.div`
  padding-bottom: 24px;
`;

const StyledRoomName = styled(Text)`
  text-transform: uppercase;
  margin-right: 4px;
`;

export default Room;
