import { Box, Grid } from "@material-ui/core";
import React from "react";

import ElErrorCodeCard from "../../../../components/ElMistakeReport/ElErrorCodeCard";
import { errorType } from "../../../../components/ElMistakeReport/ElMistakeList";
import Headline from "../../../../styles/Headline";
import Text from "../../../../styles/Text";

const HowTheAssessmentSystemWorks = () => {
  return (
    <div>
      <Box marginBottom="25px">
        <Text>
          Fejl der registreres i elinstallationsrapporten, drejer sig altid om
          ulovlige forhold. Derudover vurderes det, hvilke konkrete konsekvenser
          fejlen kan få
        </Text>
      </Box>
      <Grid container style={{ background: "#f2f2f2" }}>
        {Object.values(errorType).map((item, i) => (
          <Grid key={i} item xs={12} md={6}>
            <ElErrorCodeCard
              description={item.description}
              title={item.title}
              image={item.image}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default HowTheAssessmentSystemWorks;
