import styled from "styled-components";

const Anchor = styled.a`
  color: ${(p) => p.theme.anchorColor};
  cursor: pointer;

  &:hover {
    color: ${(p) => p.theme.anchorHoverColor};
  }
`;

export default Anchor;
