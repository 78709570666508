import { Button } from "@material-ui/core";
import {
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from "@material-ui/icons";
import { ReactNode, useState } from "react";
import Select, { StylesConfig } from "react-select";
import styled from "styled-components";

type Option = {
  value: number;
  label: string;
};

interface DropdownProps {
  selectedValue: Option | null;
  setSelectedValue: (value: Option | null) => void;
  options: Option[];
}

const selectStyles: StylesConfig<
  {
    value: number;
    label: string;
  },
  false
> = {
  control: (provided) => ({
    ...provided,
    display: "none",
  }),
  valueContainer: () => ({ display: "none" }),
  menu: () => ({
    boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)",
    minWidth: 240,
  }),
};

const Dropdown = ({
  selectedValue,
  setSelectedValue,
  options,
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <DropdownLabel>Vælg bygning</DropdownLabel>
      <DropdownWrapper
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        target={
          <DropdownButton
            endIcon={
              isOpen ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />
            }
            onClick={() => setIsOpen((prev) => !prev)}
            isSelected={isOpen}
          >
            {selectedValue?.value ? selectedValue.label : "Vælg bygning"}
          </DropdownButton>
        }
      >
        <Select
          autoFocus
          backspaceRemovesValue={false}
          // components={{ KeyboardArrowDownRounded , IndicatorSeparator: null }}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable={false}
          isSearchable={false}
          menuIsOpen
          onChange={(newValue) => {
            setSelectedValue(newValue || null);
            setIsOpen(false);
          }}
          options={options}
          placeholder="Search..."
          styles={selectStyles}
          tabSelectsValue={false}
          value={selectedValue}
        />
      </DropdownWrapper>
    </>
  );
};

const Menu = (props: JSX.IntrinsicElements["div"]) => {
  const shadow = "hsla(218, 50%, 10%, 0.1)";
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: "absolute",
        zIndex: 2,
      }}
      {...props}
    />
  );
};
const Blanket = (props: JSX.IntrinsicElements["div"]) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: "fixed",
      zIndex: 1,
    }}
    {...props}
  />
);
const DropdownWrapper = ({
  children,
  isOpen,
  target,
  onClose,
}: {
  children?: ReactNode;
  readonly isOpen: boolean;
  readonly target: ReactNode;
  readonly onClose: () => void;
}) => (
  <div style={{ position: "relative" }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);

const DropdownLabel = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
`;

const DropdownButton = styled(Button)<{ isSelected: boolean }>`
  background-color: ${(p) =>
    p.isSelected
      ? p.theme.elReportInactiveButtonColor
      : p.theme.elReportButtonColor} !important;
  color: ${(p) => p.theme.elReportButtonTextColor} !important;
  border-radius: 12px !important;
  padding: 8px 16px !important;
`;

export default Dropdown;
