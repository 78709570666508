import { CircularProgress } from "@material-ui/core";
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";

import GjensidigeReport from "./components/Gjensidige";
import { NewPageLayout } from "./components/NewPageLayout";
import { PageLayout } from "./components/PageLayout";
import CookiePolitikPage from "./pages/Politik/CookiePolitik";
import PersondataPolitikPage from "./pages/Politik/PersondataPolitik";
import HomePage from "./pages/Report";
import EjerskiftePage from "./pages/Report/Ejerskifte";
import ElInstallationsRapport from "./pages/Report/ElInstallationsRapport";
import ElrapportPage from "./pages/Report/Elrapporten";
import EnergiberegnerPage from "./pages/Report/Energiberegner";
import TilstandsrapportPage from "./pages/Report/Tilstandsrapport";
import SplashPage from "./pages/Splash";
import { featureSetUrls } from "./store/featureStore";
import { useStoreActions, useStoreState } from "./store/rootStore";
import themes from "./styles/themes";

function Router() {
  const { landingPage, theme, showElRapport } = useStoreState(
    (state) => state.Features,
  );
  const { getReport } = useStoreActions((state) => state.Report);
  const { loaded, elReport, report } = useStoreState((state) => state.Report);

  const routes = createRoutesFromChildren(
    <>
      <Route path="/" element={<SplashPage />} />

      {[...featureSetUrls, "/"].map((x) => (
        <Route key={x} path={x}>
          <Route
            path=":id"
            loader={async ({ params }) => {
              const { id } = params;

              if (!id) {
                throw new Error("No ID provided");
              }

              if (!report) {
                await getReport(id);
              }
              return null;
            }}
          >
            {!loaded || landingPage.isEnabled === undefined ? (
              <Route path="*" element={<CircularProgress />} />
            ) : landingPage.isEnabled ? (
              <>
                <Route index element={<HomePage />} />
                {theme.isEnabled && theme.value === "gjensidige" ? (
                  <Route element={<NewPageLayout />}>
                    <Route
                      path="tilstandsrapport"
                      element={<GjensidigeReport />}
                    />
                    <Route
                      path="energiberegner"
                      element={<EnergiberegnerPage />}
                    />
                    <Route path="elrapporten" element={<ElrapportPage />} />
                    <Route path="ejerskifte" element={<EjerskiftePage />} />
                  </Route>
                ) : (
                  <Route
                    path="tilstandsrapport"
                    element={<TilstandsrapportPage />}
                  />
                )}

                <Route path="cookiepolitik" element={<CookiePolitikPage />} />
                <Route
                  path="persondatapolitik"
                  element={<PersondataPolitikPage />}
                />
              </>
            ) : (
              <>
                <Route element={<PageLayout />}>
                  <Route
                    index
                    element={<Navigate to="tilstandsrapport" replace />}
                  />

                  <Route
                    path="tilstandsrapport"
                    element={<TilstandsrapportPage />}
                  />
                  {elReport && showElRapport && (
                    <Route
                      path="elinstallationsrapport"
                      element={<ElInstallationsRapport />}
                    />
                  )}
                </Route>
                <Route path="cookiepolitik" element={<CookiePolitikPage />} />
                <Route
                  path="persondatapolitik"
                  element={<PersondataPolitikPage />}
                />
              </>
            )}
          </Route>
        </Route>
      ))}
    </>,
  );

  const router = createBrowserRouter(routes);

  return (
    <ThemeProvider
      theme={theme.isEnabled ? themes[theme.value!] : themes.default}
    >
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default Router;
