import { parseISO } from "date-fns";
import produce from "immer";

import EdcFeatures from "./slices/edcFeatures";
import EstateFeatures from "./slices/estateFeatures";
import JesperNielsenFeatures from "./slices/jespernielsenFeatures";
import NyboligFeatures from "./slices/nyboligFeatures";
import { parseDate } from "../helpers/calc";
import { FeatureSetUrlTypes } from "../store/featureStore";
// @ts-ignore
import images from "../styles/logoImages";
import { ThemeEnum } from "../styles/themes";
import { Consultant, Report } from "../types/Report";

// @ts-ignore

export function disableUntil(
  report: Report,
  { day, month, year }: { day: number; month: number; year: number },
): boolean {
  const inspectionDate = parseDate(report.InspectionDate);
  if (!inspectionDate) return false;

  const endDate = parseISO(
    `${String(year).padStart(4, "0")}-${String(month).padStart(
      2,
      "0",
    )}-${String(day).padStart(2, "0")}`,
  );
  return inspectionDate >= endDate;
}

export const urlFeatureEnabled = (feature: FeatureSetUrlTypes) =>
  window.location.pathname.startsWith(`/${feature}`);

export const isElReportEnabled = (report: Report): boolean =>
  disableUntil(report, {
    day: 15,
    month: 11,
    year: 2022,
  });

type Feature<T> = {
  value?: T;
  isEnabled: boolean;
};

export const defaultFeatureList: FeatureListType = {
  energyCalculator: {
    value: false,
    isEnabled: false,
  },
  landingPage: {
    value: false,
    isEnabled: false,
  },
  introductionVideos: {
    value: [],
    isEnabled: false,
  },
  logoHeader: {
    value: "",
    isEnabled: false,
  },
  buildingExpert: {
    value: {},
    isEnabled: false,
  },
  theme: {
    value: ThemeEnum.default,
    isEnabled: false,
  },
  dictionary: {
    value: false,
    isEnabled: false,
  },
  headerTagline: {
    value: "",
    isEnabled: false,
  },
  showElRapport: {
    value: false,
    isEnabled: false,
  },
};

export type FeatureListType = {
  energyCalculator: Feature<boolean>;
  landingPage: Feature<boolean>;
  introductionVideos: Feature<string[]>;
  logoHeader: Feature<keyof typeof images>;
  buildingExpert: Feature<Consultant>;
  theme: Feature<ThemeEnum>;
  dictionary: Feature<boolean>;
  headerTagline: Feature<string>;
  showElRapport: Feature<boolean>;
};

export function DefaultFeatures(report: Report): FeatureListType {
  return produce(defaultFeatureList, (state: FeatureListType) => {
    if (!report.Consultant) return;
    state.buildingExpert.value = report.Consultant;
  });
}

export const BrandFeatures = {
  EdcFeatures,
  NyboligFeatures,
  JesperNielsenFeatures,
  EstateFeatures,
};
