import { IncidentType, Issue } from "../types/ElReport";

export const getIssueType = (issue: Issue): IncidentType => {
  if (includesIssue(issue, IncidentType.FIRE)) {
    return IncidentType.FIRE;
  } else if (includesIssue(issue, IncidentType.SHOCK)) {
    return IncidentType.SHOCK;
  } else if (includesIssue(issue, IncidentType.ILLEGAL)) {
    return IncidentType.ILLEGAL;
  } else if (includesIssue(issue, IncidentType.INVESTIGATE)) {
    return IncidentType.INVESTIGATE;
  } else {
    return IncidentType.NONE;
  }
};

export const includesIssue = (
  issue: Issue,
  issueIndex: IncidentType,
): boolean =>
  !!(
    (issue.tag_ids && issue.tag_ids.includes(issueIndex)) ||
    (issue.answers &&
      issue.answers.some((x) => x.tag_ids?.includes(issueIndex)))
  );

export const incidentTypeToIcon = (incidentType: IncidentType) => {
  switch (incidentType) {
    case 1:
      return "UndersoegNaemere";
    case 2:
      return "UlovligElinstallation";
    case 3:
      return "Stoed";
    case 4:
      return "Brand";
    default:
      return "Accept";
  }
};
