import { Button, Grid } from "@material-ui/core";
import { LockRounded } from "@material-ui/icons";
import React, { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import initGA from "../../helpers/ga";
import VTRPicture from "../../images/Andet-hus-gjensidige.png";
import EnergyCalculatorPicture from "../../images/Energiberegner_gjensidige.png";
import GjensidigeLogo from "../../images/Gjensidige.png";
import MinBoligplanImage from "../../images/Gjensidige_Husplan-pen_Symbol_Digital.png";
import ElectricityReport from "../../images/Stikkontakt_gjensidige.png";
import { useStoreState } from "../../store/rootStore";
import { Gjensidige } from "../../styles";
import Footer from "../../styles/Footer";
import Headline from "../../styles/Headline";

const { Text } = Gjensidige;

const ColoredRoundButton = styled(Button)<{
  backgroundColor: React.CSSProperties["backgroundColor"];
  color?: React.CSSProperties["color"];
}>`
  color: ${(p) => p.color ?? "white"};
  border-radius: 31px;
  min-width: 240px;
  background-color: ${(p) => p.backgroundColor};
  text-transform: none;
  margin-top: 10px;

  &:hover {
    background-color: ${(p) => p.backgroundColor};
    filter: brightness(95%);
  }
`;

const OrangeButton = "#D9A74A";
const RedButton = "#A6695B";
const TealButton = "#57C0C1";
const DarkBlueButton = "#023F88";
const WhiteButton = "#fff";

const ElementWidthPx: number = 450;
const ElementWidth = `${ElementWidthPx}px`;

function HomePage() {
  const { id } = useParams<{ id: string }>();

  if (!id) {
    throw new Error("No ID was found!");
  }

  const navigate = useNavigate();
  const ref = useRef(null);
  const { report, loaded } = useStoreState((state) => state.Report);
  const { theme: styledTheme } = useStoreState((state) => state.Features);

  useEffect(() => {
    if (loaded) {
      initGA(report);
    }
  }, [report, loaded]);

  if (!report) {
    return <p>Report not found {id}</p>;
  }

  const purpleButtons =
    styledTheme.isEnabled && styledTheme.value === "gjensidige" && "#090C33";

  const Content = [
    {
      headline: "VISUEL TILSTANDSRAPPORT",
      coloredButton: purpleButtons || OrangeButton,
      buttonText: "Se rapporten",
      navigationPath: `tilstandsrapport`,
      description:
        "Her kan du se fotos af de fejl, du finder i ejendommens tilstandsrapport. Den visuelle rapport er et tillæg til ejendommens tilstandsrapport, og den viser helt konkret, hvordan de fejl, der er på ejendommen, ser ud.",
      imgSrc: VTRPicture,
    },
    {
      headline: "ENERGIBEREGNEREN",
      coloredButton: purpleButtons || RedButton,
      buttonText: "Prøv beregneren",
      navigationPath: `energiberegner`,
      description:
        "Her ser du boligens energimærke. Prøv også energiberegneren. Den viser, hvor meget du kan spare ved at energioptimere boligen, og hvad det koster at renovere.",
      imgSrc: EnergyCalculatorPicture,
    },
    {
      headline: "VISUEL GUIDE TIL ELRAPPORTEN",
      coloredButton: purpleButtons || TealButton,
      buttonText: "Se guiden",
      navigationPath: `elrapporten`,
      description:
        "Se, hvad symbolerne i elrapporten betyder, og bliv klogere på, hvad fejlene i elrapporten dækker over, hvorfor de kan være farlige, og hvordan du får dem udbedret.",
      imgSrc: ElectricityReport,
    },
    {
      headline: "EJERSKIFTEFORSIKRING",
      coloredButton: purpleButtons || DarkBlueButton,
      buttonText: "Se mere om forsikring",
      navigationPath: `ejerskifte`,
      description:
        "En ejerskifteforsikring fra Gjensidige sikrer dig mod eventuelle skjulte skader, der ikke står i rapporterne. Den kan give dig en god tryghed i bolighandlen.",
      imgSrc: GjensidigeLogo,
    },
    {
      headline: "BOLIGLOG",
      coloredButton: WhiteButton,
      buttonTextColor: "#000",
      buttonText: <LockRounded />,
      navigationPath: "",
      description: (
        <>
          Når du bliver kunde i Gjensidige, får du adgang til din egen BoligLog.
          I BoligLog får du f.eks. adgang til Gjensidiges håndværkernetværk og
          hotline med rådgivning fra fagfolk.
          <br />
          Læs mere{" "}
          <a
            href="https://www.gjensidige.dk/partner/boliglog"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </>
      ),
      imgSrc: MinBoligplanImage,
    },
  ] as Array<{
    imgSrc: string;
    buttonText: string;
    buttonTextColor?: string;
    navigationPath: string;
    headline: string;
    description: string | React.ReactNode;
    coloredButton: string;
  }>;
  return (
    <Page ref={ref} className="Page">
      <div
        style={{
          padding: "12ch 5%",
          textAlign: "center",
        }}
      >
        <LinkHeadline h1>
          {`${report.Estate?.Address}, ${report.Estate?.ZipCode} ${report.Estate?.City}`}
        </LinkHeadline>
        <div
          style={{
            maxWidth: "1100px",
            margin: "auto",
            paddingTop: "4ch",
            paddingLeft: "48px",
            paddingRight: "48px",
            textAlign: "center",
            color: "#275954",
            fontSize: 18,
          }}
        >
          Velkommen! At handle bolig er en af livets vigtigste beslutninger og
          største investeringer. Derfor er det vigtigt, at du kan føle dig tryg
          i dit boligkøb. For at skabe tryghed får du her visuelle tillæg til
          boligens rapporter, som giver dig et dybere indblik i boligens stand
          og potentiale for eventuelle energiforbedringer. Du får også en let og
          overskuelig introduktion til, hvad de forskellige rapporter
          indeholder, og hvad indholdet betyder.
        </div>
        <div
          style={{
            maxWidth: "1100px",
            margin: "auto",
            paddingTop: "2ch",
            paddingLeft: "48px",
            paddingRight: "48px",
            textAlign: "center",
            color: "#275954",
            fontSize: 18,
          }}
        >
          En god ejerskifteforsikring er også en vigtig del af en bolighandel.
          Her kan du få rådgivning om, hvad en ejerskifteforsikring fra
          Gjensidige indeholder, og hvilke skader, som typisk vil være dækket af
          ejerskifteforsikringen. Vi håber, at dette skaber god tryghed i dit
          boligkøb.
        </div>
      </div>

      <div
        style={{ margin: "2rem 0", display: "flex", justifyContent: "center" }}
      >
        <Grid
          container
          style={{ maxWidth: "1024px" }}
          alignItems="center"
          // spacing={6}
          justifyContent="center"
        >
          {Content.map((item, index) => {
            return (
              <Grid
                item
                sm={6}
                xs={12}
                justifyContent="center"
                alignItems="center"
                style={{ display: "flex", padding: "0 3%", margin: "20px 0" }}
                key={index}
                onClick={() => {
                  try {
                    const uri = new URL(item.navigationPath);
                    if (!!uri && uri.origin !== window.location.origin) {
                      window.open(uri, "_blank");
                      return;
                    }
                  } catch {
                    // Ignore error
                  }

                  navigate(item.navigationPath);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Img
                    height="175px"
                    src={item.imgSrc}
                    width={window.innerWidth - 50}
                  />
                  <div style={{ margin: "10px 0" }}>
                    <LinkHeadline h3>{item.headline} </LinkHeadline>
                  </div>
                  <GreenText style={{ minHeight: "5ch" }}>
                    {item.description}
                  </GreenText>

                  <ColoredRoundButton
                    color={item.buttonTextColor || ("#fff" as any)}
                    backgroundColor={item.coloredButton}
                    variant="contained"
                  >
                    {item.buttonText}
                  </ColoredRoundButton>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>

      <Footer
        InspectionDate={report.InspectionDate}
        RequesterName={report.RequestorName} // Removed 18/1/2022 - report.RequestorName}
        company={report.company ? report.company : ""}
        ignoreList={["Edc"]}
        style={
          {
            backgroundColor: "white",
            width: "100%",
            marginBottom: 0,
          } as React.CSSProperties
        }
      />
    </Page>
  );
}

const Img = styled.img<{
  width?: React.CSSProperties["maxWidth"];
  height?: React.CSSProperties["maxHeight"];
}>`
  max-width: ${(props) => props.width}px;
  max-height: ${(props) => props.height};
  height: auto;
  width: auto;
`;

const GreenText = styled(Text)`
  color: #275954;
  max-width: min(${ElementWidth}, 100%);
  text-align: center;
`;

const LinkHeadline = styled(Headline)`
  color: ${(p) => p.theme.headlineColor ?? "white"};
  text-align: center;
`;

const Page = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-block-size: 100vh;
  padding: 0;
  min-height: 100vh;
  background-color: ${(p) => p.theme.introBackgroundColor ?? "#b0d9cd"};
`;

export default HomePage;
