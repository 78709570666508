import React, { PropsWithChildren } from "react";
import styled from "styled-components";

import Headline from "./Headline";
import Text from "./Text";

type ResponsiveStyle = {
  desktop?: number;
  tablet?: number;
  mobile?: number;
};

/**
 * @param type
 * @returns {JSX.Element}
 * @constructor
 */
function Intro({
  type,
  marginTop,
  children,
}: PropsWithChildren<{
  marginTop?: ResponsiveStyle;
  type: string;
}>) {
  return (
    <Container marginTop={marginTop}>
      <Headline h4 bottom={10} top={type === "Forbehold" ? 50 : 20}>
        {type}
      </Headline>
      {type === "Introduktion" && (
        <Text>
          Denne præsentation er et digitalt tillæg til den officielle og
          indberettede tilstandsrapport. Formålet med dette tillæg er at
          visualisere de skader rapporten har oplistet. På den måde er det
          nemmere at forstå skadens indhold og hvor den er lokaliseret. Den
          visuelle tilstandsrapport er alene af informativ karakter og har ingen
          sammenhæng med den ejerskifteforsikring, som tilbydes på baggrund af
          tilstandsrapporten. Du skal derfor altid huske at gennemgå den
          officielle tilstandsrapport før et eventuelt huskøb.
        </Text>
      )}
      {type === "Forbehold" && (
        <>
          <Text>
            Den visuelle tilstandsrapport kan alene betragtes som et
            hjælpeværktøj til illustration af skader. Det er den indberettede
            tilstandsrapport, der er det juridiske dokument som danner
            udgangspunkt for ejendomshandlen.
          </Text>
          <Text top={10}>
            Den visuelle tilstandsrapport er ikke omfattet af
            huseftersynsordningens regler og kan således ikke erstatte
            tilstandsrapporten.
          </Text>
        </>
      )}
      {children}
    </Container>
  );
}

const Container = styled.div<{ marginTop?: ResponsiveStyle }>`
  padding-left: 24px;
  padding-right: 24px;
  margin-top: ${(p) => (p.marginTop?.desktop ? p.marginTop.desktop : 48)}px;
  margin-bottom: 24px;

  @media (min-width: 700px) and (max-width: 1024px) {
    margin-top: ${(p) => (p.marginTop?.tablet ? p.marginTop.tablet : 148)}px;
  }
  @media (max-width: 699px) {
    margin-top: ${(p) => (p.marginTop?.mobile ? p.marginTop.mobile : 148)}px;
  }
`;

export default Intro;
