import defaultTheme from "./default";
import { ThemeType } from "../../types/Theme";

const gjensidigeColors = {
  primary: "#231F20",
  secondary: "#FFFFFF",
  accent: "#F8F5F0",
  neutral: "#090C33",
  neutralLight: "#454d91",
  beautifulDream: "#b4c3e3",
  lilac: "#dfe0e6",
  lavendar: "#e9eaee",
};

const gjensidigeTheme: ThemeType = {
  ...defaultTheme,
  headerCellColor: "#1B1B1A",
  headlineColor: gjensidigeColors.neutral,
  headerCellBg: gjensidigeColors.secondary,
  cellColor: gjensidigeColors.secondary,
  cellBg: gjensidigeColors.primary,
  damageAmount: gjensidigeColors.primary,
  cellValueColor: gjensidigeColors.primary,
  cellKeyColor: gjensidigeColors.primary,
  ringColor: gjensidigeColors.primary,
  placementLinkColor: gjensidigeColors.primary,
  anchorColor: gjensidigeColors.primary,
  headerSize: "32px",
  litraCardBg: gjensidigeColors.accent,
  dmgCardBg: gjensidigeColors.accent,
  caseOverviewCardBg: gjensidigeColors.accent,
  introBackgroundColor: gjensidigeColors.secondary,
  elReportHeaderBg: "transparent",
  elReportContentBg: "transparent",
  elReportGraphColor: gjensidigeColors.neutral,
  elReportGraphHoverColor: gjensidigeColors.neutralLight,
  elReportButtonColor: gjensidigeColors.neutral,
  elReportButtonTextColor: gjensidigeColors.secondary,
  elReportButtonHoverColor: gjensidigeColors.neutralLight,
  elReportInactiveButtonColor: gjensidigeColors.neutralLight,
  elReportAccordionPrimaryColor: gjensidigeColors.lilac,
  elReportAccordionSecondaryColor: gjensidigeColors.beautifulDream,
  elReportAccordionTextColor: gjensidigeColors.primary,
  elReportIssueBg: gjensidigeColors.lavendar,
  elReportIssueTextColor: gjensidigeColors.primary,
  elReportIssueBorderColor: gjensidigeColors.beautifulDream,
};

export default gjensidigeTheme;
