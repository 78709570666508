import React, { useRef, useEffect, useState, Fragment } from "react";
import styled from "styled-components";

import DamageImage from "./DamageImage";
import Popup from "./Popup";
import { spacifySlash } from "../helpers/text";
import { useStoreActions, useStoreState } from "../store/rootStore";
import DictionaryTextDecorator from "../styles/DictionaryTextDecorator";
import Headline from "../styles/Headline";
import HouseIcon from "../styles/HouseIcon";
import { Column, Line } from "../styles/Layout";
import Text from "../styles/Text";
import { Damage, DamageImage as DamageImageType, Floor } from "../types/Report";

type Props = {
  floors: Floor[];
  damage: Damage;
  elements: any[];
  images: DamageImageType[];
  id: string;
};

function Card({ floors, elements, damage, images, id }: Props) {
  const { openDamageTypes } = useStoreState((state) => state.Report);
  const { dictionary } = useStoreState((state) => state.Features);
  const { setDamageTypes, setSelectedDamage } = useStoreActions(
    (actions) => actions.Report,
  );

  const [cardWith, setCardWidth] = useState(331);
  const [openPlacement, setOpenPlacement] = useState(false);
  const ref = useRef<{ offsetWidth: number }>(null);

  const DictionaryText = dictionary.isEnabled
    ? DictionaryTextDecorator(Text)
    : Text;

  useEffect(() => {
    setCardWidth(ref.current ? ref.current.offsetWidth : 331);
  }, [ref.current]);

  let headerText = "Skade:";
  if (damage.BuildingPart || damage.DamagedPart) {
    headerText = `${damage.BuildingPart} (${damage.DamagedPart})`;
  }

  let count = 0;
  if (damage.SelectedElements) {
    if (damage.SelectedElements.length > 0) {
      count = damage.SelectedElements.length;
    }
  }
  if (damage.SelectedRooms) {
    if (damage.SelectedRooms.length > 0) {
      count = damage.SelectedRooms.length;
    }
  }

  const getElement = (elementId: string) => {
    if (elements) {
      const element = elements.filter((e) => e.Id === elementId);
      if (element.length > 0) {
        return element[0].Name;
      }
    }
    return "";
  };

  let rooms: Floor[] = [];
  if (floors) {
    rooms = floors.map((a) => {
      if (a.Rooms && damage.SelectedRooms) {
        return {
          ...a,
          Rooms: a.Rooms.filter((e) => damage.SelectedRooms.indexOf(e.Id) > -1),
        };
      }
      return { ...a, Rooms: [] };
    });
  }

  const selectDamage = () => {
    // @ts-ignore
    if (window !== "undefined") {
      if (window.innerWidth > 1024) {
        setSelectedDamage(damage.LocationNumber);
      }
    }
  };

  return (
    <>
      {/* @ts-ignore */}
      <Container ref={ref}>
        <ImgContainer onClick={() => selectDamage()} width={cardWith}>
          <DamageImage small width={cardWith} images={images} id={id} />
        </ImgContainer>
        <TextContainer cardWidth={cardWith}>
          <Headline h5 bottom={6} top={4}>
            {spacifySlash(headerText)}
          </Headline>
          <DictionaryText size={14}>{damage.Comment}</DictionaryText>
          <Placement onClick={() => setOpenPlacement(!openPlacement)} size={14}>
            Se Placering ({count})
          </Placement>
          <Line top={6} bottom={6} />
          <Key size={14}>Risiko </Key>
          {damage.Risk && (
            <DictionaryText size={14}>{damage.Risk}</DictionaryText>
          )}
          {damage.Risks && (
            <DictionaryText size={14}>{damage.Risks}</DictionaryText>
          )}
          {damage.Note && (
            <>
              <Line top={6} bottom={6} />
              <Key size={14}>Bemærkning</Key>
              <DictionaryText size={14}>{damage.Note}</DictionaryText>
            </>
          )}
        </TextContainer>
        <IconContainer
          onClick={() => setDamageTypes(!openDamageTypes)}
          cardWidth={cardWith}
        >
          <HouseIcon bg icon={damage.Grade} />
        </IconContainer>
      </Container>
      <Popup
        title="Skaden forefindes her:"
        open={openPlacement}
        close={() => setOpenPlacement(false)}
      >
        {damage.SelectedElements && damage.SelectedElements.length > 0 && (
          <>
            <PopupHeader>Udvendigt:</PopupHeader>
            {damage.SelectedElements.map((e: any) => (
              <Text key={e} size={14}>
                {getElement(e)}
              </Text>
            ))}
          </>
        )}
        {damage.SelectedRooms && damage.SelectedRooms.length > 0 && (
          <>
            <PopupHeader>Indvendigt:</PopupHeader>
            {rooms.map((floor) => (
              <Fragment key={floor.Name}>
                {floor.Rooms.length > 0 && (
                  <>
                    <Text size={14} bold>
                      Etage: {floor.Name}
                    </Text>
                    {floor.Rooms.map((r) => (
                      <Text size={14} key={r.Name}>
                        {r.Name}
                      </Text>
                    ))}
                  </>
                )}
              </Fragment>
            ))}
          </>
        )}
      </Popup>
    </>
  );
}

const PopupHeader = styled.div`
  color: #000000;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const Key = styled(Text)`
  font-weight: bold;
  margin-top: 4px;
  margin-bottom: 4px;
`;

const Placement = styled(Text)`
  color: ${(p) => p.theme.placementLinkColor};
  cursor: pointer;
  margin-top: 4px;
  margin-bottom: 4px;
`;

const IconContainer = styled.div<{ cardWidth: number }>`
  position: absolute;
  right: 16px;
  top: ${(p) => p.cardWidth - 54}px;
  cursor: pointer;
  @media (max-width: 1024px) {
    top: ${(p) => p.cardWidth - 49}px;
  }
`;

const TextContainer = styled.div<{ cardWidth: number }>`
  padding: 8px;
  padding-bottom: 0;
  height: ${(p) => 569 - p.cardWidth}px;
  overflow-y: auto;
  overflow-x: hidden;
  @media (max-width: 1024px) {
    height: auto;
    margin-bottom: 4px;
  }
`;

const ImgContainer = styled.div<{ width: number }>`
  height: ${(p) => `${p.width}px`};
  width: 100%;
  cursor: pointer;
`;

const Container = styled(Column)`
  background-color: ${(p) => p.theme.dmgCardBg};
  height: 569px;
  width: 100%;
  position: relative;
  @media (max-width: 1024px) {
    height: auto;
  }
`;

export default Card;
