import React, { useEffect, Fragment, useRef } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import Damages from "./Damages";
import { damageNumbers } from "../../helpers/calc";
import initGA from "../../helpers/ga";
import sortLitra from "../../helpers/sort";
import { useStoreActions, useStoreState } from "../../store/rootStore";
import { Gjensidige } from "../../styles";
import { BuildingExpert } from "../../types/Report";
import BigImages from "../BigImages";
import Popup from "../Popup";

const {
  DamageTypes,
  BuildingOverview,
  CaseOverview,
  Reservations,
  Spacing,
  Footer,
  Intro,
  Overview,
} = Gjensidige;

function GjensidigeReport() {
  const { id } = useParams<{ id: string }>();

  if (!id) {
    throw new Error("No ID was found!");
  }
  const {
    report,
    files,
    loaded,
    openDamageTypes,
    selectedDamage,
    dict,
    dictOpenWord,
  } = useStoreState((state) => state.Report);

  const { setDamageTypes, setSelectedDamage, setDictOpenWord } =
    useStoreActions((actions) => actions.Report);

  useEffect(() => {
    if (loaded) {
      initGA(report);
    }
  }, [report, loaded]);

  if (!report) {
    return <p>Report not found</p>;
  }

  if (!files) {
    return <p>Files not found!</p>;
  }
  const buildingExpert = report.Consultant as BuildingExpert;
  return (
    <>
      <Intro marginTop={{ desktop: 90 }} />
      <Overview
        fadein
        id={id}
        photo={files.casePhoto}
        litraPhotos={files.litra}
      >
        <CaseOverview
          buildings={report.Buildings}
          date={report.InspectionDate}
          buildingExpert={buildingExpert}
        />
      </Overview>
      <Reservations />
      {report.Buildings.length > 0 && (
        <PadBottom>
          {report.Buildings.sort(sortLitra).map((b) => (
            <Fragment key={b.Litra}>
              <Overview id={id} photo={files.litra[b.Litra]}>
                <BuildingOverview
                  damages={damageNumbers(
                    report.Damages.filter((d) => d.BuildingLitra === b.Litra),
                  )}
                  building={b}
                />
              </Overview>
              <Spacing height={32} />
              <Damages
                elements={b.Elements}
                floors={b.Floor}
                id={id}
                images={files.damages}
                damages={report.Damages}
                litra={b.Litra}
              />
            </Fragment>
          ))}
        </PadBottom>
      )}
      <Footer
        ignoreList={["Edc"]}
        InspectionDate={report.InspectionDate}
        RequesterName={report.RequestorName}
        company={report.company ? report.company : ""}
      />

      <Popup
        title={dictOpenWord ?? ""}
        maxWidth="sm"
        open={dictOpenWord !== null}
        close={() => {
          setDictOpenWord(null);
        }}
      >
        {/* @ts-ignore */}
        {dict[dictOpenWord]}
      </Popup>
      <Popup
        maxWidth="sm"
        title="Oversigt over skadestyper"
        open={openDamageTypes}
        close={() => setDamageTypes(false)}
      >
        <DamageTypes />
      </Popup>
      <Popup
        maxWidth="md"
        open={selectedDamage !== -1}
        close={() => setSelectedDamage(-1)}
      >
        <BigImages id={id} />
      </Popup>
    </>
  );
}

const PadBottom = styled.div`
  padding-bottom: 3ch;
`;

export default GjensidigeReport;
