import { CircularProgress } from "@material-ui/core";
import { useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

import GjensidigeReport from "./Gjensidige";
import initGA from "../helpers/ga";
import { useStoreState } from "../store/rootStore";
import Container from "../styles/Container";
import { Header } from "../styles/Gjensidige";

export const NewPageLayout = () => {
  const { report, loaded, files } = useStoreState((state) => state.Report);
  const ref = useRef(null);

  useEffect(() => {
    if (loaded) {
      initGA(report);
    }
  }, [report, loaded]);

  if (!loaded) {
    return <CircularProgress />;
  }
  if (!report) {
    return <p>Report not found</p>;
  }

  if (!files) {
    return <p>Files not found!</p>;
  }

  return (
    <Page ref={ref}>
      <Container>
        <Header
          address={`${report.Estate.Address}, ${report.Estate.ZipCode} ${report.Estate.City}`}
        />

        <MiddleCon>
          <Main className="main">
            <Outlet />
          </Main>
        </MiddleCon>
      </Container>
    </Page>
  );
};

const Page = styled.div`
  height: 100vh;
  overflow-y: auto;
  width: 100%;
`;

const MiddleCon = styled.div`
  width: 1024px;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  > * {
    margin-top: 0;
    padding-top: 2ch;
    margin-bottom: 0;
  }

  > * + * {
    padding-top: 1.5rem;
  }
`;
