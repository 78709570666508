import { Box, Grid } from "@material-ui/core";

import ElMistakeReport from "../../../components/ElMistakeReport";
import { useStoreState } from "../../../store/rootStore";
import { Text } from "../../../styles/Gjensidige";
import Headline from "../../../styles/Gjensidige/Headline";
import VimeoIframe from "../../../styles/Video";
import ElInstallationsRapport from "../ElInstallationsRapport";

function ElrapportPage() {
  const { showElRapport } = useStoreState((state) => state.Features);

  return (
    <>
      {showElRapport.isEnabled ? (
        <>
          <ElInstallationsRapport />
        </>
      ) : (
        <>
          <Headline h4 bottom={10} top={50}>
            Om elrapporten
          </Headline>
          <Box />
          <Grid container>
            <Grid item md={8} style={{ flex: 1, marginBottom: 20 }}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <VimeoIframe
                  videoid="658535293"
                  width={Math.min(612, window.innerWidth / 2)}
                />
              </Box>
            </Grid>
            <Grid item md={4} style={{ padding: "0 40px" }}>
              <Text style={{ lineHeight: "21px" }}>
                En elrapport minder om en tilstandsrapport og bruger en lignende
                vurderingsmetode, men retter sig mere specifikt mod husets
                synlige elinstallationer.
                <br />
                <br />
                I gennemgangen bliver der lavet stikprøver på stikkontakter og
                lampeudtag, og det bliver undersøgt, om der er fejl eller
                deciderede ulovligheder i husets elinstallationer.
                <br />
                <br />
                Ligesom tilstandsrapporten er elrapporten desuden obligatorisk
                for, at der kan tegnes en ejerskifteforsikring.
              </Text>
            </Grid>
          </Grid>

          <ElMistakeReport />
          <Text style={{ padding: "20px 0" }}>
            På sikkerhedsstyrelsens hjemmeside kan du læse mere om hvilke
            el-opgaver, du selv må udføre og hvilke, du skal overlade til en
            elektriker.{" "}
            <a
              target="_blank"
              href="https://www.sik.dk/privat/goer-det-sikkert/el/goer-det-selv/hvilket-elarbejde-maa-du-lave-uden-autorisation"
              rel="noreferrer"
            >
              Læs mere her.
            </a>
          </Text>
        </>
      )}
    </>
  );
}

export default ElrapportPage;
