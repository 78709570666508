import React from "react";
import styled from "styled-components";

import { getDamageType } from "../helpers/calc";
import Grey from "../images/ic_house_grey.svg";
import Red from "../images/ic_house_red.svg";
import Un from "../images/ic_house_un.svg";
import Yellow from "../images/ic_house_yellow.svg";

const images = {
  Grey,
  Red,
  Yellow,
  Un,
  Black: Un,
  K3: Red,
  K2: Yellow,
  K1: Grey,
  K0: Un,
  UN: Un,
};

/**
 * @param icon
 * @param bg
 * @returns {JSX.Element}
 * @constructor
 */
function HouseIcon({ icon, bg }: { icon: string; bg?: boolean }) {
  const ImageSource = () => (
    // @ts-ignore
    <Img src={images[getDamageType(icon).toString()]} />
  );

  if (!bg) return <ImageSource />;

  return (
    <Background>
      <ImageSource />
    </Background>
  );
}

const Background = styled.div`
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(242, 242, 242, 0.9);
  border: 2px solid #cccccc;
  z-index: 100000;
  position: relative;
`;

const Img = styled.img`
  height: 40px;
  width: 40px;
`;

export default HouseIcon;
