export interface ElReportRoot {
  data: ElReport;
}

export interface ElReport {
  id: number;
  serial: string;
  draft_serial: string;
  user_id: number;
  company_id: number;
  status: number;
  is_revision: boolean;
  is_audit: boolean;
  version: string;
  energy_mark: any;
  material_comments: any;
  bbr_comments: any;
  finishing_questions: FinishingQuestion[];
  finishing_question_revision_notes: any;
  base_report_id: any;
  base_report_serial: any;
  previous_report_serial: string;
  locked_by_user_id: any;
  locked_at: any;
  visit_at: string;
  finished_at: string;
  created_at: string;
  updated_at: string;
  deleted_at: any;
  meta: any[];
  deactivated: boolean;
  invoice_id: number;
  user: User;
  property: Property;
  seller: Seller;
  tag_ids: number[];
  tags: Tag2[];
  media: Medum[];
  final_reports: FinalReport[];
  company: Company;
  has_clones: boolean;
}

export interface FinishingQuestion {
  key: string;
  notes: any;
  value: string;
}

export interface User {
  id: number;
  active: boolean;
  initials: string;
  name: string;
  email: string;
  phone: string;
  address1: any;
  address2: any;
  postcode: any;
  city: any;
  country: any;
  authorization: any;
  consents: any;
  user_agent: string;
  guid: any;
  domain: any;
  created_at: string;
  updated_at: string;
  deleted_at: any;
  meta: any;
  reports_count: number;
  authorized_cvrs: any;
  authorized_insurance: any;
  roles: string[];
  abilities: string[];
  employee_ids: number[];
}

export interface Property {
  id: number;
  uuid: string;
  clone_id: any;
  report_id: number;
  municipality_code: number;
  property_code: number;
  bfe_number: number;
  cadastral_code: string;
  cadastral_district_code: string;
  cadastral_district_name: any;
  probate: boolean;
  address1: string;
  address2: string;
  postcode: string;
  city: string;
  year: number;
  municipality_name: string;
  lookup_at: string;
  created_at: string;
  updated_at: string;
  deleted_at: any;
  meta: any[];
  buildings: Building[];
}

export interface Building {
  id: number;
  uuid: string;
  clone_id: any;
  property_uuid: string;
  property_id: number;
  name: string;
  type: string;
  year: number;
  bbr: Bbr;
  order: number;
  created_at: string;
  updated_at: string;
  deleted_at: any;
  meta: any[];
  roof: Roof;
  litra: string;
  tag_ids: number[];
  floors: Floor[];
}

export interface Bbr {
  year: number;
  areas: Areas;
  floors: number;
  number: number;
  comments: any;
}

export interface Areas {
  roof: number;
  total: number;
  living: number;
  basement: number;
  business: number;
}

export interface Roof {
  lower: Lower;
  upper: Upper;
  basement: Basement;
  flashing: any[];
}

export interface Lower {
  under_material_id: any;
}

export interface Upper {
  slope_material_id: any;
  roofing_material_id: any;
}

export interface Basement {
  is_before: any;
}

export interface Floor {
  id: number;
  uuid: string;
  clone_id: any;
  building_uuid: string;
  building_id: number;
  name: string;
  order: number;
  created_at: string;
  updated_at: string;
  deleted_at: any;
  meta: any;
  rooms: Room[];
}

export interface Room {
  id: number;
  uuid: string;
  clone_id: any;
  floor_uuid: string;
  floor_id: number;
  name: string;
  tagmap_id: number;
  year: number;
  order: number;
  created_at: string;
  updated_at: string;
  deleted_at: any;
  meta: Meta;
  tag_ids: number[];
  issues: Issue[];
  installations?: Installation[];
}

export interface Meta {
  status: string;
  inspectionStatus: any;
  originalRoomName: string;
  questionsAnswered: boolean;
}

export interface Issue {
  id: number;
  uuid: string;
  clone_id: any;
  issuable_uuid: string;
  issuable_type: string;
  issuable_id: number;
  type: string;
  text: string;
  comments?: string;
  damage_text_id: any;
  custom_text: any;
  risk_text: any;
  risk_id: any;
  risk_comments: any;
  placements?: Placement[];
  question_id?: number;
  order: number;
  ui_created_at?: string;
  ui_updated_at?: string;
  created_at: string;
  updated_at: string;
  deleted_at: any;
  meta: any;
  answers?: Answer[];
  tag_ids?: number[];
}

export interface Placement {
  tag_id?: number;
  placement_id?: number;
  name?: string;
}

export interface Answer {
  id: number;
  uuid: string;
  clone_id: any;
  issue_uuid: string;
  issue_id: number;
  answer: string;
  text: string;
  damage_text_id: number;
  comments: any;
  order: number;
  answer_no?: number;
  created_at: string;
  updated_at: string;
  deleted_at: any;
  meta: any[];
  tag_ids: number[];
}

export interface Installation {
  id: number;
  uuid: string;
  clone_id: any;
  room_uuid: string;
  room_id: number;
  name: string;
  year: number;
  created_at: string;
  updated_at: string;
  deleted_at: any;
  meta: any[];
  order: number;
  tag_ids: number[];
  issues: Issue2[];
}

export interface Issue2 {
  id: number;
  uuid: string;
  clone_id: any;
  issuable_uuid: string;
  issuable_type: string;
  issuable_id: number;
  type: string;
  text: string;
  comments: any;
  damage_text_id: any;
  custom_text: any;
  risk_text: any;
  risk_id: any;
  risk_comments: any;
  placements: any;
  question_id: number;
  order: number;
  ui_created_at: any;
  ui_updated_at: any;
  created_at: string;
  updated_at: string;
  deleted_at: any;
  meta: any[];
}

export interface Seller {
  id: number;
  uuid: string;
  clone_id: any;
  report_id: number;
  name: string;
  email: string;
  phone: string;
  address1: string;
  address2: any;
  postcode: string;
  city: string;
  cpr: any;
  information: Information;
  personal_information: any;
  answers: Answer2[];
  manually_answered: number;
  optout_reason_comment: any;
  signed_at: any;
  created_at: string;
  updated_at: string;
  deleted_at: any;
  meta: any;
}

export interface Information {
  comment: any;
  question_60: string;
  question_61: string;
  question_62: string;
  question_63: string;
  question_64: string;
  question_61_0: string;
  sellerInformationStatus: string;
}

export interface Answer2 {
  tag: Tag;
  option: Option;
  answers: Answer3[];
  question: string;
  seller_question_id: number;
}

export interface Tag {
  id: number;
  name: string;
}

export interface Option {
  label: string;
  value: string;
}

export interface Answer3 {
  label: string;
  value: string;
  show_inline: boolean;
}

export interface Tag2 {
  id: number;
  name: string;
  type: string;
  order: number;
  group?: string;
}

export interface Medum {
  id: number;
  uuid: string;
  name: string;
  file_name: string;
  mime_type: string;
  conversions_disk: string;
  size: number;
  generated_conversions: GeneratedConversions;
  created_at: string;
  updated_at: string;
  original_url: string;
  preview_url: string;
  uri: string;
  legacy_uri: string;
  attention_uri: string;
}

export interface GeneratedConversions {
  scaled: boolean;
}

export interface FinalReport {
  id: number;
  uuid: string;
  type: string;
  serial: string;
  municipality_code: number;
  property_code: number;
  bfe_number: number;
  cadastral_code?: string;
  cadastral_district_name?: string;
  address: string;
  address2?: string;
  postcode: string;
  city: string;
  buildings_area_total?: number;
  company?: string;
  company_id?: number;
  user?: string;
  user_id?: number;
  authorization: string;
  imported: boolean;
  complete: boolean;
  problems: boolean;
  meta?: Meta2;
  visit_at?: string;
  finished_at?: string;
  created_at: string;
  updated_at: string;
  json_url: string;
  url: string;
  base_serial: string;
  report_id?: number;
  version?: string;
}

export interface Meta2 {
  eeweb_id: string;
}

export interface Company {
  id: number;
  user_id: number;
  name: string;
  email: string;
  phone: string;
  address1: string;
  address2: any;
  postcode: string;
  city: string;
  country: string;
  cvr: string;
  authorization: string;
  website: string;
  markets: number[];
  created_at: string;
  meta: any[];
}

export enum IncidentType {
  FIRE = 4,
  SHOCK = 3,
  ILLEGAL = 2,
  INVESTIGATE = 1,
  NONE = 0,
}
