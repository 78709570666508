import { format, parse, parseISO } from "date-fns";

import { DamageGradeEnum, DamageNumberType } from "../types/Damage";

export const getDamageType = (grade: string): DamageGradeEnum => {
  const uppercaseGrade = grade.toUpperCase();

  if (
    uppercaseGrade === "K3" ||
    uppercaseGrade.substring(0, 3) === "RØD" ||
    uppercaseGrade.substring(0, 3) === "RED"
  ) {
    return DamageGradeEnum.Red;
  }
  if (
    uppercaseGrade === "K2" ||
    uppercaseGrade.substring(0, 3) === "GUL" ||
    uppercaseGrade.substring(0, 3) === "YEL"
  ) {
    return DamageGradeEnum.Yellow;
  }
  if (
    uppercaseGrade === "K1" ||
    uppercaseGrade.substring(0, 3) === "GRÅ" ||
    uppercaseGrade.substring(0, 3) === "GRE"
  ) {
    return DamageGradeEnum.Grey;
  }
  if (
    uppercaseGrade === "K0" ||
    uppercaseGrade === "UN" ||
    uppercaseGrade === "U/N" ||
    uppercaseGrade.substring(0, 3) === "SOR" ||
    uppercaseGrade.substring(0, 3) === "BLA"
  ) {
    return DamageGradeEnum.Un;
  }
  return DamageGradeEnum.Un;
};

export const damageNumbers = (damages: any): DamageNumberType => {
  const numbers: DamageNumberType = { Red: 0, Yellow: 0, Grey: 0, Un: 0 };
  damages.forEach((d: any) => {
    let places = 0;
    if (d.SelectedElements) {
      places += d.SelectedElements.length;
    }
    if (d.SelectedRooms) {
      places += d.SelectedRooms.length;
    }

    const grade = getDamageType(d.Grade);
    switch (grade) {
      case DamageGradeEnum.Red:
        numbers.Red += places;
        break;
      case DamageGradeEnum.Yellow:
        numbers.Yellow += places;
        break;
      case DamageGradeEnum.Grey:
        numbers.Grey += places;
        break;
      case DamageGradeEnum.Un:
        numbers.Un += places;
        break;
      default:
        break;
    }
  });
  return numbers;
};

export const calcPan = (
  xy: "x" | "y",
  pan: string,
  width: number,
  zoom: string,
) => {
  if (pan) {
    const [x, y] = pan.split(",") as unknown as [number, number];

    if (xy === "x") {
      return ((x / 100) * width) / Number(zoom);
    }
    return ((y / 100) * width) / Number(zoom);
  }
  return 0;
};

const monthTranslations = {
  Jan: "januar",
  Feb: "februar",
  Mar: "marts",
  Apr: "april",
  May: "maj",
  Jun: "juni",
  Jul: "juli",
  Aug: "august",
  Sep: "september",
  Oct: "oktober",
  Nov: "november",
  Dec: "december",
};

function isValidDate(d: any): d is Date {
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(d) && d instanceof Date;
}

export function parseDate(inspectionDate: string): Date | undefined {
  if (!inspectionDate || inspectionDate.length < 10) {
    return undefined;
  }
  const date = inspectionDate.substring(0, 10);
  const strategies = [
    parse(date, "dd-MM-yyyy", new Date()),
    parseISO(date),
    parse(date, "MM-dd-yyyy", new Date()),
  ];

  const validDates = strategies.filter(isValidDate);

  if (!validDates || validDates.length === 0) {
    return undefined;
  }

  return validDates[0];
}

export const formatDate = (date: string) => {
  if (!date) {
    return "";
  }
  let result = date;
  try {
    const parsed = parseDate(date);

    if (!parsed) return "";

    result = `${format(parsed, "d")}. ${
      // @ts-ignore
      monthTranslations[format(parsed, "MMM")]
    } ${format(parsed, "yyyy")}`;
  } catch (e) {
    result = date;
  }
  return result;
};
