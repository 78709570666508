import { Report } from "../../types/Report";
import {
  disableUntil,
  FeatureListType,
  isElReportEnabled,
  urlFeatureEnabled,
} from "../index";

const isEnabled = (report: Report): boolean =>
  disableUntil(report, {
    day: 1,
    month: 1,
    year: 2022,
  }) || urlFeatureEnabled("theme2022");

export default function EstateFeatures(report: Report): FeatureListType {
  return {
    dictionary: {
      isEnabled: isEnabled(report),
    },
    buildingExpert: {
      isEnabled: isEnabled(report),
    },
    theme: {
      isEnabled: isEnabled(report),
      value: "estate",
    },
    logoHeader: {
      isEnabled: isEnabled(report),
      value: "Estate",
    },
    headerTagline: {
      isEnabled: isEnabled(report),
      value: "Min ejendomsmægler".toLocaleUpperCase(),
    },
    showElRapport: {
      isEnabled: isElReportEnabled(report),
    },
  } as FeatureListType;
}
