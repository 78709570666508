import React, { useMemo, useRef, useState } from "react";
import styled from "styled-components";

import Building from "./Building";
import IncidentGraph from "./Graph";
import { IntroGuide } from "./IntroGuide";
import HowTheAssessmentSystemWorks from "./IntroGuide/HowTheAssessmentSystemWorks";
import HowToPrepareElectricReportContent from "./IntroGuide/HowToPrepareElectricReportContent";
import Dropdown from "../../../components/Dropdown";
import { includesIssue } from "../../../helpers/elReportIssue";
import { useStoreState } from "../../../store/rootStore";
import { generic } from "../../../styles";
import {
  Building as BuildingModel,
  IncidentType,
} from "../../../types/ElReport";

const { Intro, Text } = generic;
const formatBuildingName = (building: BuildingModel) =>
  `${building.name} (${building.litra})`;

const introGuides = [
  {
    title: "Sådan udarbejdes elrapporten",
    content: <HowToPrepareElectricReportContent />,
  },
  {
    title: "Sådan fungerer vurderingssystemet",
    content: <HowTheAssessmentSystemWorks />,
  },
];

function ElInstallationsRapport(props: any) {
  const ref = useRef(null);
  const { elReport } = useStoreState((state) => state.Report);
  const [selectedBuilding, setSelectedBuilding] = useState<{
    value: number;
    label: string;
  } | null>(
    (elReport?.property?.buildings &&
      elReport?.property?.buildings?.length > 0 &&
      elReport?.property.buildings[0].id && {
        value: elReport?.property.buildings[0].id,
        label: formatBuildingName(elReport.property.buildings[0]),
      }) ||
      null,
  );

  const getBuildingIncidentCount = (incidentType: IncidentType) => {
    let count = 0;
    if (!elReport) return 0;
    elReport.property.buildings?.forEach((building) => {
      building.floors?.forEach((floor) => {
        floor.rooms?.forEach((room) => {
          room.issues?.forEach((issue) => {
            if (includesIssue(issue, incidentType)) {
              count++;
            }
          });
        });
      });
    });
    return count;
  };

  const allIssuesCount = useMemo(
    () =>
      elReport?.property.buildings.reduce((acc, building) => {
        return (
          acc +
          building.floors.reduce((acc, floor) => {
            return (
              acc +
              floor.rooms.reduce((acc, room) => {
                return acc + room.issues.length;
              }, 0)
            );
          }, 0)
        );
      }, 0),
    [elReport],
  );

  const allIncidentsCount = [
    IncidentType.FIRE,
    IncidentType.SHOCK,
    IncidentType.ILLEGAL,
    IncidentType.INVESTIGATE,
  ].reduce((acc, incident) => acc + getBuildingIncidentCount(incident), 0);
  const graphData = [
    IncidentType.FIRE,
    IncidentType.SHOCK,
    IncidentType.ILLEGAL,
    IncidentType.INVESTIGATE,
  ].map((incident) => ({
    incidentAmount: getBuildingIncidentCount(incident),
    incidentType: incident,
  }));
  const options =
    elReport?.property.buildings.map((building) => ({
      value: building.id,
      label: formatBuildingName(building),
    })) || [];
  return (
    <>
      <IntroContainer>
        <Intro type="Om den visuelle elrapport" marginTop={{ desktop: 70 }}>
          <IntroDescription>
            <Text>
              Den visuelle elrapport, som du ser her på siden, er et digitalt
              tillæg til den officielle og indberettede elinstallationssrapport.
              Formålet med dette tillæg er at skabe et overblik over
              anmærkninger og gøre det nemt at forstå de enkelte fejl.
            </Text>
            <Text top={20}>
              Den visuelle elrapport er alene af informativ karakter og har
              ingen sammenhæng med den ejerskifteforsikring, som tilbydes på
              baggrund af elinstallationsrapporten.
            </Text>
            <Text top={20}>
              Bemærk, at sælgeroplysninger og oplysninger om bygningsdele eller
              rum, der ikke kunne besigtiges da rapporten blev lavet, ikke
              fremgår her. Du skal derfor altid huske at gennemgå den officielle
              elinstallationsrapport før et eventuelt huskøb.
            </Text>
          </IntroDescription>
          <IntroGuideWrapper>
            {introGuides.map((guide, index) => (
              <IntroGuide
                title={guide.title}
                content={guide.content}
                key={index}
              />
            ))}
          </IntroGuideWrapper>
        </Intro>
        <Intro
          type="Oversigt over anmærkninger"
          marginTop={{ mobile: 40, desktop: 70 }}
        >
          <Text bottom={20}>
            I denne bolig er der <b>{allIncidentsCount}</b> anmærkninger i i alt{" "}
            <b>{allIssuesCount}</b> undersøgte forhold.
          </Text>
          <IncidentGraph incidentData={graphData} />
        </Intro>
      </IntroContainer>

      {elReport && elReport.property.buildings.length > 0 && (
        <ToggleContainer>
          <DropdownWrapper>
            <Dropdown
              selectedValue={selectedBuilding}
              setSelectedValue={setSelectedBuilding}
              options={options}
            />
          </DropdownWrapper>

          {selectedBuilding && (
            <Building
              key={selectedBuilding.value}
              building={elReport.property.buildings.find(
                (x) => x.id === selectedBuilding.value,
              )}
            />
          )}
        </ToggleContainer>
      )}
    </>
  );
}

const DropdownWrapper = styled.div``;

const ToggleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 6px;
  padding: 0 18px;
  max-width: 1000px;
`;

const IntroContainer = styled.div`
  padding-bottom: 12px;
  margin-top: 0;

  @media (min-width: 700px) {
    display: grid;
    grid-template-columns: 60% 40%;
  }
`;
const IntroDescription = styled.div`
  margin-bottom: 20px;
`;

const IntroGuideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  //justify-content: space-between;
`;

export default ElInstallationsRapport;
