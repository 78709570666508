import { ThemeType } from "../../types/Theme";

const defaultTheme: ThemeType = {
  headlineColor: "#122D3A",
  damageAmount: "#5d827e",
  cellKeyColor: "#a5b9b6",
  cellValueColor: "#5d827e",
  usageImageWidth: "32px",
  usageImageHeight: "28px",
  textColor: "#122D3A",
  headerAddressAlign: "flex-start",
  headerSize: "32px",
  pageLinkColor: "#61dafb",
  ringColor: "#35c596",
  placementLinkColor: "#35c596",
  anchorColor: "#35c596",
  anchorHoverColor: "#a5b9b6",
  footerColor: "#f7f7f9",
  textFontSize: "15px",
  headerFontFamily: "'Helvetica', sans-serif",
  containerBg: "#ffffff",
  litraCardBg: "#f7f7f9",
  dmgCardBg: "#f2f2f2",
  caseOverviewCardBg: "#f7f7f9",
  elReportHeaderBg: "#eaf9f5",
  elReportContentBg: "#eaf9f5",
  elReportGraphColor: "#A7D8CB",
  elReportGraphHoverColor: "#35c596",
  elReportButtonColor: "#65D5A1",
  elReportButtonTextColor: "black",
  elReportButtonHoverColor: "#35c596",
  elReportInactiveButtonColor: "#CCF0E5",
  elReportAccordionPrimaryColor: "#bceedc",
  elReportAccordionSecondaryColor: "#a1d9cc",
  elReportAccordionTextColor: "black",
  elReportIssueBg: "#e6f8f1",
  elReportIssueTextColor: "black",
  elReportIssueBorderColor: "#a1d9cc",
};

export default defaultTheme;
