import estateTheme from "./estate";
import gjensidigeTheme from "./gjensidige";
import jesperNielsenTheme from "./jesperNielsen";
import nyBoligTheme from "./nybolig";
import { ThemeType } from "../../types/Theme";

export enum ThemeEnum {
  default = "default",
  nybolig = "nybolig",
  estate = "estate",
  jespernielsen = "jespernielsen",
  gjensidige = "gjensidige",
}

const themes: Record<ThemeEnum, ThemeType> = {
  default: {
    headlineColor: "#001a50",
    damageAmount: "#001a50",
    cellKeyColor: "#757575",
    cellValueColor: "#001a50",
    headerCellColor: "#cbcbcb",
    headerCellBg: "#001a50",
    cellColor: "white",
    cellBg: "#727f9a",
    usageImageWidth: "40px",
    usageImageHeight: "35px",
    textColor: "#000000",
    headerAddressAlign: "flex-end",
    headerSize: "40px",
    pageLinkColor: "#61dafb",
    ringColor: "#35c596",
    placementLinkColor: "#35c596",
    anchorColor: "#35c596",
    footerColor: "",
    textFontSize: "15px",
    containerBg: "#ffffff",
    litraCardBg: "#f7f7f9",
    dmgCardBg: "#f2f2f2",
    caseOverviewCardBg: "#f7f7f9",
  },
  nybolig: nyBoligTheme,
  estate: estateTheme,
  jespernielsen: jesperNielsenTheme,
  gjensidige: gjensidigeTheme,
};

export default themes;
