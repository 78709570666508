import { ApiResponse } from "apisauce";
import { Action, action, thunk, Thunk } from "easy-peasy";

import {
  dictionary,
  getBuildingExpert,
  getElectricalReport,
  getEnergyLabel,
  getFilelist,
  getQuestions,
  getRedirect,
  getReport,
} from "../api";
import { ElReport } from "../types/ElReport";
import { ElReportQuestion } from "../types/ElReportQuestion";
import { DamageImage, Files, Report } from "../types/Report";

interface ReportStoreModel {
  page: string;
  report: Report | null;
  elReport: ElReport | null;
  elReportQuestions: ElReportQuestion[];
  files: Files | null;
  dict: { [key: string]: string } | null;
  dictOpenWord: string | null;
  loaded: boolean;
  openDamageTypes: boolean;
  selectedDamage: number;
  images: DamageImage[];
  setReport: Action<ReportStoreModel, Report>;
  setElReport: Action<ReportStoreModel, ElReport>;
  setElReportQuestions: Action<ReportStoreModel, ElReportQuestion[]>;
  setFiles: Action<ReportStoreModel, Files>;
  setDict: Action<ReportStoreModel, { [key: string]: string }>;
  setDictOpenWord: Action<ReportStoreModel, string | null>;
  setLoaded: Action<ReportStoreModel, boolean>;
  setDamageTypes: Action<ReportStoreModel, boolean>;
  setSelectedDamage: Action<ReportStoreModel, number>;
  getReport: Thunk<ReportStoreModel, string>;
}

function jsonResponseOk<T, R>(response: ApiResponse<T, R>): boolean {
  return (
    response.ok &&
    response.headers !== undefined &&
    (response.headers["content-type"]?.includes("application/json") ?? false)
  );
}

const reportStore: ReportStoreModel = {
  page: "Report",
  report: null,
  elReport: null,
  elReportQuestions: [],
  files: null,
  dict: null,
  dictOpenWord: null,
  loaded: false,
  openDamageTypes: false,
  selectedDamage: -1,
  images: [],
  setReport: action((state, payload) => {
    state.report = payload;
  }),
  setElReport: action((state, payload) => {
    const filteredIRIssues = payload.property.buildings
      .map((b) => ({
        ...b,
        floors: b.floors.map((f) => ({
          ...f,
          rooms: f.rooms
            .map((r) => ({
              ...r,
              issues: r.issues ? r.issues.filter((i) => i.text !== "IR") : [],
            }))
            .filter((r) => r.issues.length > 0),
        })),
      }))
      .filter((b) => b.floors.some((f) => f.rooms.length > 0));

    state.elReport = {
      ...payload,
      property: { ...payload.property, buildings: filteredIRIssues },
    };
  }),
  setElReportQuestions: action((state, payload) => {
    state.elReportQuestions = payload;
  }),
  setFiles: action((state, payload) => {
    state.files = payload;
  }),
  setDict: action((state, payload) => {
    state.dict = payload;
    // for gjensidige theme intro to dictionary
    state.dict.Fagtermer =
      "Hvis du klikker på de markerede fagtermer, får du en forklaring af hvad de dækker over. Eksempelvis for 'Gavl': Endemuren på en bygning (typisk den korteste ydervæg). Udtrykket anvendes ofte alene om den trekant der dannes op mod taget, gavltrekanten.";
  }),
  setDictOpenWord: action((state, payload) => {
    state.dictOpenWord = payload;
  }),
  setLoaded: action((state, payload) => {
    state.loaded = payload;
  }),
  setDamageTypes: action((state, payload) => {
    state.openDamageTypes = payload;
  }),
  setSelectedDamage: action((state, payload) => {
    state.selectedDamage = payload;

    if (state.files?.damages[payload]) {
      state.images = state.files.damages[payload];
    } else {
      state.images = [];
    }
  }),
  getReport: thunk(async (actions, payload) => {
    const redirect = await getRedirect(payload);
    //  Redirect if such is found
    if (
      redirect !== undefined &&
      redirect.data !== null &&
      redirect.data !== undefined &&
      redirect.data !== ""
    ) {
      window.location.replace("/" + redirect.data);
    }

    const report = await getReport(payload);
    const files = await getFilelist(payload);
    const dict = await dictionary();

    if (jsonResponseOk(report)) {
      if (!report.data) return;

      if (!report.data.Consultant) {
        const reportWithProcessId = report.data as Report & {
          ProcessId: string;
        };
        if (reportWithProcessId.ProcessId) {
          const buildingExpert = await getBuildingExpert(
            reportWithProcessId.ProcessId,
          );

          if (
            jsonResponseOk(buildingExpert) &&
            report.data &&
            buildingExpert.data
          ) {
            report.data.Consultant = {
              Company: buildingExpert.data.Company,
              Email: buildingExpert.data.Email,
              Name: buildingExpert.data.Name,
              Phone: buildingExpert.data.Phone,
              Title: `Bygningssagkyndig i ${buildingExpert.data.Company}`,
            };
          }
        }
      }

      if (!report.data.EnergymarkSerial) {
        const energyMarkSerial = await getEnergyLabel(payload);

        if (jsonResponseOk(energyMarkSerial) && energyMarkSerial.data) {
          report.data.EnergymarkSerial = energyMarkSerial.data;
        } else {
          report.data.EnergymarkSerial = "0";
        }
      }

      actions.setReport(report.data);
    }

    if (jsonResponseOk(files) && files.data) {
      actions.setFiles(files.data);
    }

    if (dict.ok && dict.data) actions.setDict(dict.data);

    const elReport = await getElectricalReport(payload);

    if (jsonResponseOk(elReport) && elReport.data) {
      const elReportQuestions = await getQuestions();

      if (jsonResponseOk(elReportQuestions) && elReportQuestions.data) {
        actions.setElReport(elReport.data.data);
        actions.setElReportQuestions(elReportQuestions.data.data);
      }
    }

    if (report.ok && files.ok) {
      actions.setLoaded(true);
    }
  }),
};

export default reportStore;
