import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText as MDialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { Message } from "@material-ui/icons";
import React from "react";
import styled from "styled-components";

import {
  getIssueType,
  incidentTypeToIcon,
} from "../../../helpers/elReportIssue";
import Anchor from "../../../styles/Anchor";
import SText from "../../../styles/Text";
import UsageImage from "../../../styles/UsageImage";
import { Issue as IssueModel } from "../../../types/ElReport";
import { ElReportQuestion } from "../../../types/ElReportQuestion";

const MAX_LENGTH = 500; // Define the maximum length before splitting into columns

function Issue({
  issue,
  questions,
}: {
  issue: IssueModel;
  questions: ElReportQuestion[];
}) {
  const [helpTextOpen, setHelpTextOpen] = React.useState(false);

  const openHelpText = () => {
    setHelpTextOpen(true);
  };

  const closeHelpText = () => {
    setHelpTextOpen(false);
  };

  const icon = incidentTypeToIcon(getIssueType(issue));

  const helpText = questions.find(
    (_: any) => _.id === issue.question_id,
  )?.help_text;

  return (
    <QuestionContainer>
      <GridElementText>
        <Text>
          {questions.find((_: any) => _.id === issue.question_id)?.text}
        </Text>
      </GridElementText>
      <GridElementText withBorder>
        <Text>
          {issue?.text}. {issue.answers?.map((_: any) => _.text).join("\n")}
        </Text>
        <Text>{issue.comments}</Text>
        {issue.answers && (
          <div>
            <p>Forstå fejlen</p>
            <AnswerAnchor onClick={openHelpText}>
              <Message /> læs mere
            </AnswerAnchor>
          </div>
        )}
      </GridElementText>
      <GridElementImage>
        {icon && <UsageImage size={35} usage={icon} />}
      </GridElementImage>
      <React.Fragment>
        <Dialog
          open={helpTextOpen}
          onClose={closeHelpText}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Forstå fejlen</DialogTitle>
          <DialogContent>
            {helpText && (
              <DialogContentText
                id="alert-dialog-description"
                longText={helpText.length > MAX_LENGTH}
              >
                {helpText}
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeHelpText}>Luk</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </QuestionContainer>
  );
}

const DialogContentText = styled(MDialogContentText)<{ longText: boolean }>`
  column-count: ${(p) => (p.longText ? 2 : 1)};
  column-gap: 20px;
`;

const QuestionContainer = styled.div`
  display: grid;
  grid-template-columns: 60% 30% 10%;
  //grid-gap: 2px;
  border: ${(p) => `1px solid ${p.theme.elReportIssueBorderColor}`};
  padding: 1px;
  min-height: 61px;

  &:not(:first-child) {
    border-top: none;
  }

  @media (max-width: 699px) {
    grid-template-columns: 4fr 1fr;
    grid-template-rows: repeat(2, 1fr);
    padding-bottom: 16px;
  }
`;

const GridElementText = styled.div<{ withBorder?: boolean }>`
  background-color: ${(p) => p.theme.elReportIssueBg};
  padding: 8px;
  border-left: ${(p) =>
    p.withBorder ? `2px solid ${p.theme.elReportIssueBorderColor}` : "none"};
  border-right: ${(p) =>
    p.withBorder ? `2px solid ${p.theme.elReportIssueBorderColor}` : "none"};
  //color: blue;

  @media (max-width: 699px) {
    &:first-child {
      grid-area: 1 / 1 / 2 / 6;
    }

    &:nth-child(2) {
      grid-area: 2 / 1 / 3 / 5;
    }

    &:nth-child(3) {
      grid-area: 2 / 5 / 3 / 6;
    }
  }
`;

const GridElementImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(p) => p.theme.elReportIssueBg};
  padding: 16px;
`;

const Text = styled(SText)`
  color: ${(p) => p.theme.elReportIssueTextColor};
`;

const AnswerAnchor = styled(Anchor)`
  display: flex;
  text-decoration: underline;
  gap: 4px;
`;

export default Issue;
