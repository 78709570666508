import styled from "styled-components";

import Accept from "../images/usage/accept-check.svg";
import Andet from "../images/usage/Andet@2x.png";
import Anneks from "../images/usage/Anneks@2x.png";
import Baghus from "../images/usage/Baghus@2x.png";
import Beboelse from "../images/usage/Beboelse@2x.png";
import BeboelseCarport from "../images/usage/BeboelseCarport@2x.png";
import BeboelseGarage from "../images/usage/BeboelseGarage@2x.png";
import BeboelseOverdkketterrasse from "../images/usage/BeboelseOverdkketterrasse@2x.png";
import Beboelsetilbygning from "../images/usage/Beboelsetilbygning@2x.png";
import BeboelseUdhus from "../images/usage/BeboelseUdhus@2x.png";
import Brndeskur from "../images/usage/Brndeskur@2x.png";
import Carport from "../images/usage/Carport@2x.png";
import Carportudhus from "../images/usage/Carportudhus@2x.png";
import Drivhus from "../images/usage/Drivhus@2x.png";
import Facade from "../images/usage/Facade@2x.png";
import Fyringscentral from "../images/usage/Fyringscentral@2x.png";
import Garage from "../images/usage/Garage@2x.png";
import Garageudhus from "../images/usage/Garageudhus@2x.png";
import Halvtag from "../images/usage/Halvtag@2x.png";
import Havestueudestue from "../images/usage/Havestueudestue@2x.png";
import Hnsehus from "../images/usage/Hnsehus@2x.png";
import Karnap from "../images/usage/Karnap@2x.png";
import Lade from "../images/usage/Lade@2x.png";
import Legehus from "../images/usage/Legehus@2x.png";
import Maskinhus from "../images/usage/Maskinhus@2x.png";
import Overdkketterrasse from "../images/usage/Overdkketterrasse@2x.png";
import Overdkning from "../images/usage/Overdkning@2x.png";
import Brand from "../images/usage/risiko-for-brand.png";
import Stoed from "../images/usage/risiko-for-stoed.png";
import Sommerhus from "../images/usage/Sommerhus@2x.png";
import Stald from "../images/usage/Stald@2x.png";
import Staldbygning from "../images/usage/Staldbygning@2x.png";
import Tilbygning from "../images/usage/Tilbygning@2x.png";
import Udhusskur from "../images/usage/Udhusskur@2x.png";
import UlovligElinstallation from "../images/usage/ulovlige-elinstallationer.png";
import UndersoegNaemere from "../images/usage/undersoeges-naermere.png";
import Vrksted from "../images/usage/Vrksted@2x.png";

const images = {
  Accept,
  Andet,
  Anneks,
  Baghus,
  Beboelse,
  BeboelseCarport,
  BeboelseGarage,
  BeboelseOverdkketterrasse,
  Beboelsetilbygning,
  BeboelseUdhus,
  Brand,
  Brndeskur,
  Carport,
  Carportudhus,
  Drivhus,
  Facade,
  Fyringscentral,
  Garage,
  Garageudhus,
  Halvtag,
  Havestueudestue,
  Hnsehus,
  Karnap,
  Lade,
  Legehus,
  Maskinhus,
  Overdkketterrasse,
  Overdkning,
  Sommerhus,
  Stald,
  Staldbygning,
  Stoed,
  Tilbygning,
  Udhusskur,
  UlovligElinstallation,
  UndersoegNaemere,
  Vrksted,
};

const stripUsage = (name: string) => {
  if (name === undefined) {
    return "Facade";
  }
  name = name.replace("æ", "");
  name = name.replace("ø", "");
  name = name.replace("å", "");
  name = name.replace("/", "");
  name = name.replace("-", "");
  name = name.replace(/ /g, "");
  return name;
};

/**
 * @param usage
 * @returns {JSX.Element}
 * @constructor
 */
function UsageImage({ usage, size }: { usage: string; size?: number }) {
  let icon = images.Andet;
  // @ts-ignore
  if (images[stripUsage(usage)]) {
    // @ts-ignore
    icon = images[stripUsage(usage)];
  }
  return <Img src={icon} size={size} />;
}

const Img = styled.img<{ size?: number }>`
  width: ${(p) => (p.size ? `${p.size}px` : p.theme.usageImageWidth)};
  height: ${(p) => (p.size ? `${p.size}px` : p.theme.usageImageHeight)};
`;

export default UsageImage;
