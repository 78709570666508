import { has } from "lodash";
import ReactGA from "react-ga4";

const initGA = (report: any) => {
  if (window.location.hostname === "visueltilstandsrapport.dk") {
    let requestor = "None";
    if (has(report, "RequestorName")) {
      requestor = report.RequestorName;
    }
    let company = "internal";
    if (has(report, "company")) {
      company = report.company;
    } else if (has(report, "Company")) {
      // @ts-ignore
      company = report.Company;
    }
    ReactGA.initialize("G-MQEZ128RQ8");
    // ReactGA.set({ dimension1: company, dimension2: requestor});
    ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, dimension1: company, dimension2: requestor })
  }
};

export default initGA;
