import styled from "styled-components";

import { useStoreState } from "../../../store/rootStore";
import { Text } from "../../../styles/Gjensidige";
import VimeoIframe from "../../../styles/Video";

type ResponsiveStyle = {
  desktop?: number;
  tablet?: number;
  mobile?: number;
};

function EjerskiftePage() {
  const { report } = useStoreState((state) => state.Report);

  if (!report) {
    return <p>Report not found</p>;
  }

  return (
    <Container marginTop={{ desktop: 90 }}>
      <Text>
        En god ejerskifteforsikring er en vigtig del af en bolighandel.
        Ejerskifteforsikringen dækker skjulte skader, der ikke står i tilstands-
        og el-installationsrapporten, og som allerede eksisterede, da du overtog
        boligen.
      </Text>
      <br />
      <Text>
        Se filmen og få rådgivning om, hvad en ejerskifteforsikring indeholder,
        og hvilke skader som typisk vil være dækket.
      </Text>
      <br />
      <VimeoIframe
        videoid="703649854"
        width={Math.min(1024, window.innerWidth)}
      />
      <br />
      <Text>
        Vil du vide mere om ejerskifteforsikring i Gjensidige, så klik på
        knappen herunder.
      </Text>
      <LinkButton
        href="https://www.gjensidige.dk/ejerskifteforsikring"
        target="_blank"
        rel="noreferrer"
      >
        Læs mere hos Gjensidige
      </LinkButton>
    </Container>
  );
}

const LinkButton = styled.a`
  background-color: #7c55ff;
  color: white;
  text-decoration: none;
  font-size: 0.875rem;
  padding: 10px 60px;
  border-radius: 31px;
  margin: 30px 0px;
  cursor: pointer;
  line-height: 1.75;
  font-weight: 500;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  border-width: 0;

  &:hover {
    color: white;
    filter: brightness(95%);
    box-shadow: 0px 2px 2px #888888;
  }
`;

const Container = styled.div<{ marginTop?: ResponsiveStyle }>`
  margin-top: ${(p) => (p.marginTop?.desktop ? p.marginTop.desktop : 48)}px;

  @media (min-width: 700px) and (max-width: 1024px) {
    margin-top: ${(p) => (p.marginTop?.tablet ? p.marginTop.tablet : 148)}px;
  }
  @media (max-width: 699px) {
    margin-top: ${(p) => (p.marginTop?.mobile ? p.marginTop.mobile : 148)}px;
  }
`;
export default EjerskiftePage;
