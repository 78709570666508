import defaultTheme from "./default";
import { ThemeType } from "../../types/Theme";

// https://designnybolig.nykredit.dk/da/design/farver/
const nyboligcolors = {
  // primary
  tidepoolLight: "#9ebac6",
  tidepool: "#809EAD",
  stoneshore: "#122D3A",
  // accent
  sunnyday: "#FFFDB2",
  morningorange: "#FFB495",
  // neutral
  sand: "#EDE8E6",
  warmgray: "#C4C6C5",
  // actions
  nyboliggreen: "#00A06A",
  beige: "#f7f6f5",
  // Elreport
  wentworth: "#355362",
  cascadingWhite: "#f8f6f5",
  flowerDress: "#ede8e6",
};

const nyBoligTheme: ThemeType = {
  ...defaultTheme,
  headerCellColor: "#F2F2F2",
  headerCellBg: nyboligcolors.stoneshore,
  cellColor: "#FFFFFF",
  cellBg: nyboligcolors.tidepool,
  ringColor: nyboligcolors.nyboliggreen,
  placementLinkColor: nyboligcolors.nyboliggreen,
  anchorColor: nyboligcolors.nyboliggreen,
  anchorHoverColor: "#3fad8a",
  headlineColor: nyboligcolors.stoneshore,
  damageAmount: nyboligcolors.stoneshore,
  cellValueColor: nyboligcolors.tidepool,
  cellKeyColor: nyboligcolors.stoneshore,
  textFontFamily: "'Franklin Gothic', sans-serif",
  headerFontFamily: "'Franklin Gothic', sans-serif",
  litraCardBg: nyboligcolors.sand,
  dmgCardBg: nyboligcolors.sand,
  caseOverviewCardBg: nyboligcolors.cascadingWhite,
  elReportHeaderBg: "transparent",
  elReportContentBg: "transparent",
  elReportGraphColor: nyboligcolors.wentworth,
  elReportGraphHoverColor: nyboligcolors.tidepool,
  elReportButtonColor: nyboligcolors.wentworth,
  elReportButtonTextColor: nyboligcolors.beige,
  elReportButtonHoverColor: nyboligcolors.tidepool,
  elReportInactiveButtonColor: nyboligcolors.tidepool,
  elReportAccordionPrimaryColor: nyboligcolors.flowerDress,
  elReportAccordionSecondaryColor: nyboligcolors.tidepool,
  elReportAccordionTextColor: nyboligcolors.wentworth,
  elReportIssueBg: nyboligcolors.cascadingWhite,
  elReportIssueTextColor: nyboligcolors.wentworth,
  elReportIssueBorderColor: "#D7CFCC",
};

export default nyBoligTheme;
